import axios from "axios";
import FingerPrint from "@fingerprintjs/fingerprintjs";
import bowser from "bowser";
import { BACKEND_URL } from "./constants";
import appStore from "./stores/app";
import uiStore from "./stores/ui";
import { saveApplicationData } from "./storage";
import { State } from "./stores/admin";

axios.defaults.baseURL = BACKEND_URL;

async function getBrowserFingerprint() {
  const fp = await FingerPrint.load();
  const { visitorId } = await fp.get();
  return visitorId;
}

export async function getTrips() {
  if (!uiStore.online) {
    console.error("[backend] [getTrips] client is not online");
    return;
  }
  if (uiStore.tripLoading) {
    console.error("[backend] [getTrips] already loading");
    return;
  }

  uiStore.tripLoading = true;

  try {
    const { data, status } = await axios.get("/trip");

    if (status !== 200) {
      console.error("[backend] [getTrips] invalid answer");
      return;
    }

    appStore.loadTrips(data);
    uiStore.connectionError = false;
  } catch (e) {
    console.warn("[backend] [getTrips] connectivity error");
    uiStore.connectionError = true;
  }

  uiStore.tripLoading = false;
}

export async function getUserVisits() {
  if (!uiStore.online) {
    console.error("[backend] [getUserVisits] client is not online");
    return;
  }

  const userId = appStore.user;

  try {
    const response = await axios.post("/visits", { userId });

    if (!response.data.success) {
      console.log("[backend] [getUserVisits] user has empty visits");
      return;
    }
    console.log("[backend] [getUserVisits] visits loaded");
    appStore.loadUserVisits(response.data.results);
    uiStore.connectionError = false;
  } catch (e) {
    console.warn("[backend] [getUserVisits] connectivity error");
    uiStore.connectionError = true;
  }

  uiStore.tripLoading = false;
}

export async function copyUserVisits(newUserId: number) {
  if (!uiStore.online) {
    console.error("[backend] [setUserVisits] client is not online");
    return;
  }
  const userId = appStore.user;
  try {
    await axios.post("/copyVisits", { userId, newUserId });
    uiStore.connectionError = false;
  } catch (e) {
    console.warn("[backend] [setUserVisits] connectivity error");
    uiStore.connectionError = true;
  }
}

export async function copyUserActivities(newUserId: number) {
  if (!uiStore.online) {
    console.error("[backend] [copyUserActivities] client is not online");
    return;
  }
  const userId = appStore.user;
  try {
    await axios.post("/copyActivities", { userId, newUserId });
    uiStore.connectionError = false;
  } catch (e) {
    console.warn("[backend] [copyUserActivities] connectivity error");
    uiStore.connectionError = true;
  }
}

export async function createUser() {
  if (!uiStore.online) {
    console.error("[backend] [createUser] client is not online");
    return;
  }

  if (appStore.user) {
    console.warn("[backend] [createUser] user is already logged in");
    return;
  }

  const hash = await getBrowserFingerprint();
  const info = JSON.stringify(
    bowser.getParser(window.navigator.userAgent).getResult()
  );
  try {
    const response = await axios.post("/user", { hash, info });

    if (response.status !== 200) {
      console.error("[backend] [createUser] invalid answer");
      return;
    }

    appStore.login(response.data.userId, response.data.token, null, null, false);
    appStore.trips.list?.forEach(async (tripId) => 
        await fetchTripDetails(tripId)
      );
    uiStore.connectionError = false;
  } catch (e) {
    console.warn("[backend] [createUser] connectivity error");
    uiStore.connectionError = true;
  }
}

export async function signUp(emailReg: String, passwordReg: String) {
  if (!uiStore.online) {
    console.error("[backend] [signUp] client is not online");
    return {result: -1, token: null, userId: null};
  }

  if (appStore.email) {
    console.warn("[backend] [signUp] user is already logged in");
    return {result: -1, token: null, userId: null};
  }

  const hash = await getBrowserFingerprint();
  const info = JSON.stringify(
    bowser.getParser(window.navigator.userAgent).getResult()
  );

  try {
    const response = await axios.post("/signUp", {email: emailReg,
       password: passwordReg, hash: hash, info: info });  

    if (response.data.success) {
      uiStore.connectionError = false;
      return {result: 0, token: response.data.token, userId: response.data.userId}
    }
    else {
      console.error("[backend] [signUp] user already registered");
      return {result: 1, token: null, userId: null};
    }
  } catch (e) {
    console.warn("[backend] [signUp] connectivity error");    
    uiStore.connectionError = true;
    return {result: -1, token: null, userId: null};
  }
}

export async function signIn(emailLog: String, passwordLog: String) {
  if (!uiStore.online) {
    console.error("[backend] [signIn] client is not online");
    return -1;
  }

  if (appStore.email) {
    console.warn("[backend] [signIn] user is already logged in");
    return -1;
  }

  try {
    const response = await axios.post("/signIn", {email: emailLog, password: passwordLog });

    if (response.data.success) {
      appStore.signOut();
      appStore.login(response.data.userId, response.data.token, response.data.nickname, response.data.email, response.data.admin);
      await getUserVisits();
      appStore.trips.list?.forEach(async (tripId) => 
        await fetchTripDetails(tripId)
      );
      uiStore.connectionError = false;
      return 0;
    }
    else {
      console.error("[backend] [signIn] user not found");
      return 1;
    }
  } catch (e) {
    console.warn("[backend] [signIn] connectivity error");    
    uiStore.connectionError = true;
    return -1;
  }
}

export async function changePassword(email: String | null, oldPassword: String, newPassword: String) {
  if (!uiStore.online) {
    console.error("[backend] [changePassword] client is not online");
    return -1;
  }
  try {
    const response = await axios.post("/changePassword", {email, oldPassword, newPassword });

    if (response.data.success) {
      uiStore.connectionError = false;
      return 0;
    }
    else {
      console.error("[backend] [changePassword] incorrect password");
      return 1;
    }
  } catch (e) {
    console.warn("[backend] [changePassword] connectivity error");    
    uiStore.connectionError = true;
    return -1;
  }
}

export async function resetPassword(token: String | undefined, newPassword: String) {
  if (!uiStore.online) {
    console.error("[backend] [resetPassword] client is not online");
    return -1;
  }
  try {
    const response = await axios.post("/resetPassword", { token, newPassword });

    if (response.data.success) {
      uiStore.connectionError = false;
      return 0;
    }
  } catch (e) {
    console.warn("[backend] [resetPassword] connectivity error");    
    uiStore.connectionError = true;
    return -1;
  }
}

export async function changeNickname(email: String | null, newNickname: String) {
  if (!uiStore.online) {
    console.error("[backend] [changeNickname] client is not online");
    return -1;
  }
  try {
    const response = await axios.post("/changeNickname", {email, newNickname });

    if (response.data.nickname != null) {
      appStore.updateNickname(response.data.nickname);
      uiStore.connectionError = false;
      return 0;
    }
    else {
      console.error("[backend] [changeNickname] nickname is taken ");
      return 1;
    }
  } catch (e) {
    console.warn("[backend] [changeNickname] connectivity error");    
    uiStore.connectionError = true;
    return -1;
  }
}

export async function verifyUser(token: String | undefined) {
  if (!uiStore.online) {
    console.error("[backend] [verifyUser] client is not online");
    return -1;
  }
  try {
    const response = await axios.post("/verifyUser", { token });

    if (response.data.success) {
      uiStore.connectionError = false;
      return 0;
    }
    else {
      console.error("[backend] [verifyUser] error while verifying ");
      return 1;
    }
  } catch (e) {
    console.warn("[backend] [verifyUser] connectivity error");    
    uiStore.connectionError = true;
    return -1;
  }
}

export async function getUserToken(email: String) {
  if (!uiStore.online) {
    console.error("[backend] [getUserToken] client is not online");
    return {result: -1, token: null}
  }
  try {
    const response = await axios.post("/getUserToken", { email });

    if (response.data.success) {
      uiStore.connectionError = false;
      return {result: 0, token: response.data.token}
    }
    else {
      console.error("[backend] [getUsetToken] error while getting token ");
      return {result: 1, token: null}
    }
  } catch (e) {
    console.warn("[backend] [getUserToken] connectivity error");    
    uiStore.connectionError = true;
    return {result: -1, token: null}
  }
}

export async function sendEmail(fromEmail: String, toEmail: String, message: String, header: String) {
  if (!uiStore.online) {
    console.error("[backend] [sendEmail] client is not online");
    return;
  }
  try {

    await axios.post("/sendEmail", { fromEmail, toEmail, message, header });

  } catch (e) {
    console.warn("[backend] [/sendEmail] connectivity error");    
    uiStore.connectionError = true;
  }
}

export async function userVisitSpot(
  id: string,
  currentPosition?: { lat: number, lng: number }
) {
  if (!uiStore.currentUserPosition && !currentPosition) {
    console.error("[backend] [userVisitSpot] currentUserPosition undefined");
    return;
  }

  const { lat, lng } = currentPosition || uiStore.currentUserPosition!;

  try {
    const response = await axios.post(`/user/spot/${id}`, {
      gps: { lat, lng }
    });

    if (response.status !== 200) {
      console.error("[backend] [userVisitSpot] invalid answer");
      return;
    }

    delete appStore.pendingOperations.spot[id];
  } catch (e) {
    console.warn("[backend] [userVisitSpot] connectivity error");

    if (!appStore.pendingOperations.spot[id]) {
      appStore.pendingOperations.spot[id] = { lat, lng };
    }

    uiStore.connectionError = true;
  }

  saveApplicationData();
}

export async function userLogActivity(
  spotId: string,
  { answer, correct }: { answer: string, correct: boolean }
) {
  if (!uiStore.currentUserPosition) {
    console.error("[backend] [userLogActivity] currentUserPosition undefined");
    return;
  }

  try {
    const response = await axios.post(`/user/activity/${spotId}`, { answer, correct });

    if (response.status !== 200) {
      console.error("[backend] [userLogActivity] invalid answer");
      return;
    }

    delete appStore.pendingOperations.activity[spotId];
  } catch (e) {
    console.warn("[backend] [userLogActivity] connectivity error");

    if (!appStore.pendingOperations.activity[spotId]) {
      appStore.pendingOperations.activity[spotId] = { answer, correct };
    }

    uiStore.connectionError = true;
  }

  saveApplicationData();
}

export async function fetchTripDetails(tripId: number) {
  if (!uiStore.online) {
    console.error("[backend] [getTripDetails] client is not online");
    return;
  }

  uiStore.tripLoading = true;

  if (!appStore.trips.collection[tripId]) {
    await getTrips();
  }

  try {
    const [spots, files, ratings] = await Promise.all([
      axios.get(`/trip/${tripId}/spot`),
      axios.get(`/trip/${tripId}/files`),
      axios.get(`/trip/${tripId}/rating`)
    ]);

    if (files.status !== 200 || spots.status !== 200) {
      console.error("[backend] [getTripDetails] invalid answer");
    }

    appStore.saveTripDetails(tripId, {
      spot: spots.data,
      file: files.data,
      rating: ratings.data,
    });

    uiStore.connectionError = false;
  } catch (e) {
    console.warn("[backend] [getTripDetails] connectivity error");
    uiStore.connectionError = true;
  }

  uiStore.tripLoading = false;
}

export async function setRating(rating: number, tripId: number, userId: number) {
  if (!uiStore.online) {
    console.error("[backend] [setRating] client is not online");
    return;
  }
  try {
    await axios.post('/setRating', {rating, tripId, userId});
    uiStore.connectionError = false;

  } catch (e) {
    console.warn("[backend] [setRating] connectivity error");
    uiStore.connectionError = true;
  }
}

export async function setTrip(trip: State) {
  if (!uiStore.online) {
    console.error("[backend] [setTrip] client is not online");
    return;
  }
  try {
    const tripAttr = trip.tripAttr;
    console.log("frotnend post")
    const tripResponse = await axios.post('/trip/setTrip', { tripAttr });
    console.log("after frotnend post")
    console.log(trip.spotsAttr)
    for (const spotAttr of trip.spotsAttr) {
      const tripId = tripResponse.data.tripId;
      const spotResponse = await axios.post('/trip/setSpot', { spotAttr, tripId });
      console.log("after set spot")
      for (const activityAttr of spotAttr.activitiesAttr) {
        const spotId = spotResponse.data.spotId;
        await axios.post('/trip/setActivity', { activityAttr, spotId });        
      }
      console.log("after set activity")
    }
    uiStore.connectionError = false;

  } catch (e) {
    console.error(e);
    console.warn("[backend] [setTrip] connectivity error");
    uiStore.connectionError = true;
  }
}

export async function deleteTrip(tripId: number) {
  if (!uiStore.online) {
    console.error("[backend] [deleteTrip] client is not online");
    return;
  }
  try {
    await axios.post(`/trip/deleteTrip/${tripId}`);
    uiStore.connectionError = false;

  } catch (e) {
    console.warn("[backend] [setSpot] connectivity error");
    uiStore.connectionError = true;
  }
}

export async function deleteSpot(spotId: number) {
  if (!uiStore.online) {
    console.error("[backend] [deleteSpot] client is not online");
    return;
  }
  try {
    await axios.post(`/trip/deleteSpot/${spotId}`);
    uiStore.connectionError = false;

  } catch (e) {
    window.alert("Stanoviště nelze smazat, protože je napojeno na hodnoty user_visit. Lze mazat iba stanoviště, které není napojeno na uživatele.")
    uiStore.connectionError = true;
  }
}

export async function deleteActivity(spotId: number, difficulty: number) {
  if (!uiStore.online) {
    console.error("[backend] [deleteActivity] client is not online");
    return;
  }
  try {
    await axios.post(`/trip/deleteActivity/${spotId}/${difficulty}`);
    window.alert("Aktivita úspěšne zmazána.");
    uiStore.connectionError = false;

  } catch (e) {
    window.alert("Aktivitu nelze smazat, protože je napojena na hodnoty user_activity. Lze mazat iba aktivitu, která není napojena na uživatele.")
    uiStore.connectionError = true;
  }
}

export async function checkPendingOperations() {
  if (Object.keys(appStore.pendingOperations.spot)) {
    Object.entries(appStore.pendingOperations.spot).forEach(([id, value]) => {
      userVisitSpot(id, value);
    });
  }

  if (Object.keys(appStore.pendingOperations.activity)) {
    Object.entries(appStore.pendingOperations.activity).forEach(([id, value]) => {
      userLogActivity(id, value);
    });
  }
}

let timer: NodeJS.Timer;
export function updateOnlineStatus() {
  if (navigator.onLine === true || timer === null) {
    checkPendingOperations();
    timer = setInterval(checkPendingOperations, 10000);
  } else {
    clearInterval(timer);
  }
}

window.addEventListener("load", updateOnlineStatus);
window.addEventListener("online", updateOnlineStatus);
window.addEventListener("offline", updateOnlineStatus);
