import { CircularProgress } from '@mui/material';
import appStore from '../stores/app';
import { TripCard } from './TripCard';
import React from 'react';
import { observer } from 'mobx-react-lite';

export default observer(function FilteredTripCards({
  stroller,
  lock,
  transportation,
  duration,
  length,
}: {
  stroller: string;
  lock: string;
  transportation: string;
  duration: number;
  length: number;
}) {
  return (
    <div style={{ marginTop: '15px' }}>
      {!appStore.trips.list ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={100} />
        </div>
      ) : (
        appStore.trips.list.map((tripId) => {
          const trip = appStore.trips.collection[tripId];
          let matches = true;
          if (stroller != 'X') {
            if (stroller == 'strollerOnly' && !trip.baby_carriage) {
              matches = false;
            } else if (stroller == 'noStroller' && trip.baby_carriage) {
              matches = false;
            }
          }
          if (lock != 'X') {
            const isLocked = trip.password && !appStore.trips.unlocked[tripId];
            if (lock == 'unlocked' && isLocked) {
              matches = false;
            } else if (lock == 'locked' && !isLocked) {
              matches = false;
            }
          }
          if (transportation != 'X') {
            if (transportation == 'walk' && trip.bicycle) {
              matches = false;
            } else if (transportation == 'bike' && !trip.bicycle) {
              matches = false;
            }
          }
          if (duration < trip.duration) {
            matches = false;
          }
          if (length < trip.length) {
            matches = false;
          }
          if (matches) {
            return <TripCard key={tripId} tripId={tripId} />;
          }
        })
      )}
    </div>
  );
});
