import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WalkIcon from "@mui/icons-material/DirectionsWalk";
import { Box, Card, CardActionArea, CardContent, CardHeader, CardMedia, IconButton, Tooltip } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { Link, useLocation } from "react-router-dom";
import { CachedImage } from "./CachedImage";
import appStore from "../stores/app";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import uiStore from "../stores/ui";

export default observer(function ProgressBar() {

    const score = appStore.activeTrip != null ? appStore.getTripScore(appStore.activeTrip) : { list:[], percentage: 0};
    const stopsVisitedAlready = score.list.filter(a => a).length;
    const location = useLocation();

    useEffect(() => {
        window.addEventListener("scroll", uiStore.handleScroll);
        return () => {
        window.removeEventListener("scroll", uiStore.handleScroll);
        };
    }, []);

    return (
        <div>
        { (appStore.activeTrip != null && (location.pathname != `/trip/${appStore.activeTrip}/description` && 
        location.pathname != `/trip/${appStore.activeTrip}/spots` && location.pathname != `/trip/${appStore.activeTrip}/map` &&
        !location.pathname.includes("/activity") && !uiStore.scrolledBottom)) && 
        <Card sx={{ position: "fixed", bottom: 10, left: 16, right: 16, zIndex: 1000 }} variant='outlined' >
            <CardActionArea component={Link} to={`/trip/${appStore.activeTrip}/description`}>
            <Box sx={{mb: -1}}> 
                <CachedImage
                image={appStore.trips.collection[appStore.activeTrip].profile_photo.file_url}
                cacheName={`trip-${appStore.activeTrip}`}
                >
                {image => <CardMedia
                    sx={{ height: 40 }}
                    image={image}
                    title={appStore.activeTrip ? appStore.trips.collection[appStore.activeTrip].profile_photo.file_name : undefined}
                />}
                </CachedImage>
            </Box>
            <CardHeader 
                title={appStore.trips.collection[appStore.activeTrip].title}
                titleTypographyProps={{variant:'h6' }}
            /> 
            <CardContent sx={{ display: "flex", height: 20, mt: -3, ml: -2 }}>
                <Tooltip
                placement="top"
                title="Časový odhad náročnosti cesty"
                enterTouchDelay={1}
                >
                <IconButton aria-label="Čas" size="large">
                    <AccessTimeIcon fontSize="small" />
                    <span style={{ fontSize: 18 }}>
                    {" "}
                    &nbsp;
                    {appStore.trips.collection[appStore.activeTrip].duration}
                    hod.
                    </span>
                </IconButton>
                </Tooltip>

                <Tooltip
                placement="top"
                title="Přibližná délka celé cesty"
                enterTouchDelay={1}
                >
                <IconButton aria-label="Vzdálenost" size="large">
                    <WalkIcon fontSize="small" />
                    <span style={{ fontSize: 18 }}>
                    {" "}
                    &nbsp;
                    {appStore.trips.collection[appStore.activeTrip].length}
                    km
                    </span>
                </IconButton>
                </Tooltip>
            </CardContent>
            <LinearProgress
                sx={{top: 0, width: "100%" }}
                variant="determinate"
                value={stopsVisitedAlready/(score.list.length == 0 ? 1 : score.list.length) * 100}
            />
            </CardActionArea>
        </Card> }
        </div>
    );
});