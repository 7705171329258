import React from "react";
import { Navigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import appStore from "../stores/app";
import uiStore from "../stores/ui";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";

export default observer(function Loader() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!uiStore.appLoading) {
    return <Navigate to={appStore.user ? "/home/list" : "/intro"} />;
  }

  return (
    <Box sx={{
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <CircularProgress size={100} />
    </Box>
  );
})
