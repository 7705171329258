import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from "axios";
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import appStore from '../stores/app';
import uiStore from "../stores/ui";
import { changePassword } from '../backend';
import { InputAdornment, IconButton, Dialog } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const theme = createTheme();

export default function Password() {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
    });
  };

  React.useEffect(() => {
    uiStore.appBar = {
      page: "password"
    };

    window.scrollTo(0, 0);
  }, []);
  

  const [oldPasswordLog, setOldPasswordLog] = React.useState(''); 
  const [newPasswordLog, setNewPasswordLog] = React.useState('');
  const [newPasswordConfirmLog, setNewPasswordConfirmLog] = React.useState('');
  const [showPasswordOld, setShowPasswordOld] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const handleClickShowPasswordOld = () => setShowPasswordOld(!showPasswordOld);
  const handleMouseDownPasswordOld = () => setShowPasswordOld(!showPasswordOld);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
  const handleMouseDownPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);

  const [open, setOpen] = React.useState(false);

  const [message, setMessage] = React.useState('');
  const [messageColor, setMessageColor] = React.useState('white');

  const navigate = useNavigate();

  const passwordChange = async () => {
    if (newPasswordLog == '' || newPasswordConfirmLog == '') {
      setMessageColor('red');
      setMessage('Vyplňte prosím údaje.')
      return;
    }
    if (newPasswordLog == newPasswordConfirmLog) {
      const response = await changePassword(appStore.email, oldPasswordLog, newPasswordLog);    
      if (response == 0) {
        setOpen(true);

      }
      else if (response == 1) {
        setMessageColor('red');
        setMessage('Nesprávné původní heslo.')
      }
      else {
        setMessageColor('red');
        setMessage('Při změně hesla nastala nečakaná chyba. Prosím kontaktujte podporu LApky.');
      }
    }
    else {
        setMessageColor('red');
        setMessage('Hesla se musí zhodovat.')
    }
  };

  return (
    <Box sx={{
        padding: '10px',
        '& > div': {
        marginBottom: '10px',
        },
    }}>
        <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography align='center' color={messageColor}>
            {message}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate >
          <Typography component="h1" variant="h5" sx={{pt: 2 }}>
            Původní heslo
          </Typography>
          <TextField
              margin="normal"
              required
              fullWidth
              name="oldPassword"
              label="Původní heslo"
              type={showPasswordOld ? "text" : "password"}
              id="oldPassword"
              autoComplete="current-password"
              onChange={(e) => {setOldPasswordLog(e.target.value)}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordOld}
                      onMouseDown={handleMouseDownPasswordOld}
                    >
                      {showPasswordOld ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Typography component="h1" variant="h5" sx={{pt: 3 }}>
                Nové heslo
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="Nové heslo"
              type={showPassword ? "text" : "password"}
              id="newPassword"
              onChange={(e) => {setNewPasswordLog(e.target.value)}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Typography component="h1" variant="h5" sx={{pt: 3 }}>
                Potvrdit heslo
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPasswordConfirm"
              label="Potvrdit heslo"
              type={showPasswordConfirm ? "text" : "password"}
              id="newPasswordConfirm"
              onChange={(e) => {setNewPasswordConfirmLog(e.target.value)}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordConfirm}
                      onMouseDown={handleMouseDownPasswordConfirm}
                    >
                      {showPasswordConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={passwordChange}
            >
              Změnit
            </Button>
          </Box>
        </Box>
        <Dialog open={open} PaperProps={{ sx: { width: "80%"} }}>
          <Box
            sx={{
              marginTop: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CheckCircleOutlineIcon color='success' sx={{ fontSize: 70 }} />
            <Typography variant="h5" align='center'>
              Heslo úspěšne změněno.
            </Typography>  
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              onClick={() => navigate(-1)}
            >
              OK
            </Button>         
          </Box>
        </Dialog>
      </Container>
    </ThemeProvider>
    </Box >
    
  );
}