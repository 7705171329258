// @ts-expect-error React-leaflet's missing bindings
import { withLeaflet, GridLayer } from "react-leaflet";
import L from "leaflet";

class Grid extends GridLayer {
  props: any;
  createLeafletElement() {
    const { url, cacheName } = this.props;

    const Layer = L.TileLayer.extend({
      createTile: (coords: any, done: any) => {
        const { x, y, z } = coords;
        // this is the url for tile
        const tileUrl = url
          .replace("{x}", x)
          .replace("{y}", y)
          .replace("{z}", z);

        // create new instance of Image (later filled with cached data blob)
        const tileImage = new Image();
        // use the actual url as fallback in case we didn't cached it
        tileImage.src = tileUrl;

        try {
          caches.has(cacheName).then(exists => {
            if (!exists) {
              done();
              return;
            }

            caches.open(cacheName).then(cache => {
              cache.match(tileUrl).then(tileResponse => {
                // in the case we haven't found a cached tile let's return fallback
                if (!tileResponse) {
                  done();
                  return;
                }

                // we have something, let's transform the blob and render it!
                tileResponse.blob().then(blob => {
                  const tileImageData = URL.createObjectURL(blob);
                  tileImage.src = tileImageData;
                  done();
                });
              });
            });
          });
        } catch (e) {
          console.warn("CachedLayer issue", e);
          done();
        }

        return tileImage;
      }
    });

    return new (Layer as any)(url);
  }
}

export default withLeaflet(Grid);
