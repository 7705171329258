import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Title from './Title';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import appStore from '../../stores/app';
import { Toolbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import { FormContext } from '../../FormContext';
import { observer } from 'mobx-react-lite';
import { deleteSpot, getTrips } from '../../backend';

export default observer(function Spots({tripId, newTrip}: {tripId: number, newTrip: boolean}) {

  const navigate = useNavigate();
  const tripSpots = newTrip ? [] : appStore.trips.collection[tripId].spots;

  const formContext = useContext(FormContext);
  if (!formContext) {
      throw new Error('You tried to use FormContext outside of its provider.');
  }
  const { formState, dispatch } = formContext;

  const editSpot = (spotId: number) => {
    const existingSpot = formState.spotsAttr.find(spot => spot.id == spotId);
    if (existingSpot) {
      dispatch({type: 'SET_SPOT_ATTR', payload: existingSpot });
    };
    navigate(`/admin/editSpot/${spotId}`);
  }

  const deleteSpotConfirm = async (spotId: number) => {
    const canDelete = window.confirm("Jste si jisti, že chcete smazat stanoviště? Změny nelze vrátit zpět.");
    if (canDelete) {
      await deleteSpot(spotId);
      window.alert("Stanoviště úspěšne zmazáno.");
      await getTrips();
    }
  }

  const deleteTemporarySpotConfirm = async (spotId: number) => {
    const canDelete = window.confirm("Jste si jisti, že chcete smazat stanoviště? Změny nelze vrátit zpět.");
    if (canDelete) {
      dispatch({ type: 'DELETE_SPOT', payload: spotId });
      window.alert("Stanoviště úspěšne zmazáno.");
    }
  }

  return (
    <React.Fragment>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Title>Seznam stanovišť</Title>
        <IconButton
          onClick={() => navigate(`/admin/editSpot/new`)}
          style={{ backgroundColor: "#006bcc" }} 
          color="inherit"
          aria-label="Add"   
          size='medium'   
          >
          <AddIcon sx={{color: 'white'}} fontSize='medium'/>
        </IconButton>
      </Toolbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Pořadí</TableCell>
            <TableCell>Název</TableCell>
            <TableCell>GPS_lng</TableCell>
            <TableCell>GPS_lat</TableCell>
            <TableCell align="right">
              Upravit
            </TableCell>
            <TableCell align="right">
              Smazat
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>     
        {formState.spotsAttr.map((spot) => {
            if (!tripSpots.some(id => id == spot.id)) {
              return (
                <TableRow key={Math.floor(Math.random() * (1000000000 - 1000000) + 1000000)}>
                 <TableCell>{"NEW"}</TableCell>
                 <TableCell>{spot.serialNumber}</TableCell>
                 <TableCell>{spot.title}</TableCell>
                 <TableCell>{spot.gps.lng}</TableCell>
                 <TableCell>{spot.gps.lat}</TableCell>
                 <TableCell align='right'>
                   <IconButton
                     onClick={() => editSpot(spot.id)}
                     color="inherit"
                     aria-label="Edit"
                     size="large">
                     <ModeEditIcon sx={{ color: 'blue'}}/>
                   </IconButton>
                 </TableCell>
                 <TableCell align='right'>
                   <IconButton
                      onClick={() => deleteTemporarySpotConfirm(spot.id)}
                     color="inherit"
                     aria-label="Delete"
                     size="large">
                     <DeleteIcon sx={{ color: 'red'}}/>
                   </IconButton>
                 </TableCell>
               </TableRow>
              ) 
            }
          })}     
          {tripSpots.map((spotId) => {
            const existingSpot = formState.spotsAttr.find(spot => spot.id == spotId);
            const serialNumber = existingSpot ? existingSpot.serialNumber : appStore.trips.spot[spotId].serial_number;
            const title = existingSpot ? existingSpot.title : appStore.trips.spot[spotId].title;
            const gpsLng = existingSpot ? existingSpot.gps.lng : appStore.trips.spot[spotId].gps.lng;
            const gpsLat = existingSpot ? existingSpot.gps.lat : appStore.trips.spot[spotId].gps.lat;

            return (
             <TableRow key={spotId}>
              <TableCell>{spotId}</TableCell>
              <TableCell>{serialNumber}</TableCell>
              <TableCell>{title}</TableCell>
              <TableCell>{gpsLng}</TableCell>
              <TableCell>{gpsLat}</TableCell>
              <TableCell align='right'>
                <IconButton
                  onClick={() => editSpot(spotId)}
                  color="inherit"
                  aria-label="Edit"
                  size="large">
                  <ModeEditIcon sx={{ color: 'blue'}}/>
                </IconButton>
              </TableCell>
              <TableCell align='right'>
                <IconButton
                  onClick={() => deleteSpotConfirm(spotId)}
                  color="inherit"
                  aria-label="Delete"
                  size="large">
                  <DeleteIcon sx={{ color: 'red'}}/>
                </IconButton>
              </TableCell>
            </TableRow>
            )            
          })}          
        </TableBody>
      </Table>
    </React.Fragment>
  );
});
