import * as React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChildIcon from "@mui/icons-material/ChildFriendly";
import Tooltip from "@mui/material/Tooltip";
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import GetAppIcon from "@mui/icons-material/GetApp";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WalkIcon from "@mui/icons-material/DirectionsWalk";
import Button from "@mui/material/Button";
import { Box, Divider, CircularProgress, Card, CardHeader, CardMedia, CardContent, Dialog, Rating, TextField, Grid, FormControlLabel, Switch } from "@mui/material";
import appStore from "../../stores/app";
import uiStore from "../../stores/ui";
import { CachedHtmlView } from "../../components/CachedHtmlView";
import { cacheTrip, getTripFiles, removeTripFromCache } from "../../storage";
import { CachedImage } from "../../components/CachedImage";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { filesize } from "filesize";
import StarIcon from '@mui/icons-material/Star';
import MailIcon from '@mui/icons-material/Mail';
import ShareIcon from '@mui/icons-material/Share';
import { useState } from 'react';
import { sendEmail, setRating } from "../../backend";

export default observer(function Description() {
  const { id } = useParams();
  const tripId = parseInt(id || '', 10);
  if (!tripId) return null;

  const trip = appStore.trips.collection[tripId];
  const cached = appStore.trips.cached[tripId];
  const { list } = appStore.getTripScore(tripId);
  const stopsVisitedAlready = list.filter(a => a).length;

  const [openRating, setOpenRating] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [userRating, setUserRating] = React.useState<number | null>(null);

  const averageRating = appStore.getTripRatingAverage(tripId);
  const ratingCount = trip.ratings.length;

  const [openContact, setOpenContact] = useState(false);
  const [email, setEmail] = useState(appStore.email || '');
  const [contactMessage, setContactMessage] = useState('');

  const submitRating = () => {
    if (userRating != null && appStore.user != null) {
      setRating(userRating, tripId, appStore.user);
      setShowMessage(true);
    }   
  }

  const contactAuthor = () => {
    if (email != '' && contactMessage != '') {
      sendEmail(email, trip.author, contactMessage, `Správa od uživatele LApky - ${trip.title}`);
      setShowMessage(true);
    }
  }

  const mobileShare = () => {
    const shareData = { title: trip.title, url: window.location.href }
    try {
      if (navigator.canShare && navigator.canShare(shareData)) {
        navigator
          .share(shareData)
          .then(() => console.info("Shared successful."))
          .catch((error) => {
            console.error("Sharing failed ..", error);
        });
      }    
    } catch (e) {
      console.error(e);
    }
  } 

  const manageActiveTrip = () => {
    if (appStore.activeTrip == null || appStore.activeTrip != tripId) {
      appStore.makeTripActive(tripId);
    }
    else {
      appStore.deactivateTrip();
    }
  }

  const manageTripStops = () => {
    if (!appStore.trips.showSpots[tripId]) {
      appStore.viewTripSpots(tripId);
    }
    else {
      appStore.hideTripSpots(tripId);
    }
  }

  return (
    <Card>
      <CachedImage image={trip.profile_photo.file_url} cacheName={`trip-${tripId}`}>
        {image => <CardMedia
          image={image}
          title={trip.profile_photo.file_name}
          sx={{ height: 0, paddingTop: '"56.25%" // 16:9', }}
        />}
      </CachedImage>
      <CardHeader
        title={trip.title}
        subheader={`Navštíveno ${stopsVisitedAlready} zastavení z ${list.length}`}
        action={
          <div>
            {cached ? (
              <IconButton
                aria-label="Child"
                size="large"
                color="primary"
                onClick={() => removeTripFromCache(tripId)}
              >
                <OfflinePinIcon />
                &nbsp; <span style={{ fontSize: 12 }}>staženo<br />off-line!</span>
              </IconButton>
            ) : !uiStore.hasConnectionIssue() ? (
              <Button size="small" color="primary" onClick={() => cacheTrip(tripId)}>
                {uiStore.downloadCachePending ? (
                  <CircularProgress disableShrink size={20} />
                ) : (
                  <IconButton aria-label="Child" size="large">
                    <GetAppIcon />
                    &nbsp;<span style={{ fontSize: 14 }}>stáhnout<br />off-line</span>
                    <span style={{ fontSize: 10 }}><br />
                      ({filesize(getTripFiles(tripId).length * 15000) as string})
                    </span>
                  </IconButton>
                )}
              </Button>
            ) : null}
          </div>
        }
      />

      <CardContent sx={{ paddingTop: '0px !important' }}>
        <Typography component="div">
          <CachedHtmlView cacheName={`trip-${tripId}`}>
            {trip.motivation}
          </CachedHtmlView>
        </Typography>

        <Tooltip
          placement="top"
          title="Časový odhad náročnosti cesty"
          enterTouchDelay={1}
        >
          <IconButton aria-label="Čas" size="large">
            <AccessTimeIcon fontSize="small" />
            <span style={{ fontSize: 18 }}>
              {" "}
              &nbsp;
              {trip.duration}
              hod.
            </span>
          </IconButton>
        </Tooltip>

        <Tooltip
          placement="top"
          title="Přibližná délka celé cesty"
          enterTouchDelay={1}
        >
          <IconButton aria-label="Vzdálenost" size="large">
            <WalkIcon fontSize="small" />
            <span style={{ fontSize: 18 }}>
              {" "}
              &nbsp;
              {trip.length}
              km
            </span>
          </IconButton>
        </Tooltip>

        {trip.baby_carriage && (
          <Tooltip
            placement="top"
            title="Vhodné pro kočárky"
            enterTouchDelay={1}
          >
            <IconButton aria-label="Child" size="large">
              <ChildIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {trip.bicycle && (
          <Tooltip
            placement="top"
            title="Vhodné pro jízdu na kole"
            enterTouchDelay={1}
          >
            <IconButton aria-label="Bike" size="large">
              <DirectionsBikeIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </CardContent>

      <CardContent sx={{ paddingTop: '0px !important' }}>
        <Box sx={{ width: '90%', justifyContent: 'center', alignItems: 'center', color: "text.secondary"}}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={4}>
              <Box sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => setOpenRating(true)}>
                <IconButton aria-label="Star" size="large" sx={{ pl: ratingCount >= 5 ? 3 : 4.2 }} >
                  <StarIcon fontSize="medium" />
                  { ratingCount >= 5 && <span style={{ fontSize: 20 }}>
                    {" "}
                    &nbsp;
                    {averageRating}
                  </span> }
                </IconButton>
              </Box>
              <Typography sx={{pl: 2, whiteSpace: 'nowrap'}} variant="body2">{ratingCount >= 5 ? (ratingCount + " hodnocení") : "Ohodnotit" }</Typography>
            </Grid>

            <Grid item xs={4}>
              { trip.author != '' && 
                <Box sx={{display: 'flex', flowDirection: 'column', alignItems: 'center', cursor: 'pointer', mt: 2.2}} onClick={() => setOpenContact(true)}>
                  <IconButton aria-label="Contact" size="large" sx={{display: 'flex', flexDirection: 'column'}}>
                    <MailIcon fontSize="medium" />
                    <span style={{ fontSize: 15 }}>
                      {" "}&nbsp;Kontaktovat<br />autora
                    </span>
                  </IconButton>
                </Box> 
              }
            </Grid>

            <Grid item xs={4}>
              <Box sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={mobileShare}>
                <IconButton aria-label="Star" size="large" >
                  <ShareIcon fontSize="medium" />
                  <span style={{ fontSize: 15 }}>
                    {" "}
                    &nbsp;
                    Sdílet
                  </span>
                </IconButton>
              </Box> 
            </Grid>
          </Grid>
        </Box>

        <Box
            sx={{
              padding: '20px',
              marginTop: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
          <FormControlLabel
            control={
              <Switch 
                checked={appStore.activeTrip == null || appStore.activeTrip != tripId ? false : true} 
                color="primary" 
                onChange={manageActiveTrip} />
              }
            label="Aktivovat trasu"
            sx={{
              '& .MuiFormControlLabel-label': {
                wordWrap: 'break-word',
                textAlign: 'center',
                fontSize: 15,
                color: "#747474"
              }}}
            labelPlacement="bottom"            
          />
          {!trip.spotsAlwaysShown && <FormControlLabel
            control={
              <Switch 
                checked={appStore.trips.showSpots[tripId] ? true : false} 
                color="primary" 
                onChange={manageTripStops} />
              }
            label="Zobrazit všechny body"
            sx={{
              '& .MuiFormControlLabel-label': {
                wordWrap: 'break-word',
                textAlign: 'center',
                fontSize: 15,
                color: "#747474"               
              }}}
            labelPlacement="bottom"            
          /> }
        </Box>              
      </CardContent>

      <Divider />
      <CardContent>
        <h4>Popis cesty</h4>
        <Typography component="div">
          <CachedHtmlView cacheName={`trip-${tripId}`}>
            {trip.description}
          </CachedHtmlView>
        </Typography>
      </CardContent>

      <Dialog open={openRating} PaperProps={{ sx: { width: "80%"} }} onClose={() => {setOpenRating(false); setShowMessage(false);}}>
          <Box
            sx={{
              padding: '10px',
              marginTop: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            { showMessage && <Typography align='center' color='green' variant="subtitle2">
              Ďekujeme za zpětnou vazbu.
            </Typography> }
            <Typography variant="h5">
              Přidat hodnocení trasy
            </Typography>  
            <Divider />
            <Rating              
              name="simple-controlled"
              size="large"
              value={userRating}
              onChange={(event, newValue) => {
                setUserRating(newValue);
              }}
              sx={{
                mt: 1,
                fontSize: "3rem"
              }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              onClick={submitRating}
            >
              Odeslat
            </Button>         
          </Box>
        </Dialog>

        <Dialog open={openContact} PaperProps={{ sx: { width: "80%"} }} onClose={() => {setOpenContact(false); setShowMessage(false)}}>
          <Box
            sx={{
              padding: '10px',
              marginTop: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            { showMessage && <Typography align='center' color='green' variant="subtitle2">
              Správa odeslána.
            </Typography> }
            <Typography variant="h5">
              Kontaktovat autora
            </Typography>  
            <Divider />
            <TextField
              margin="normal"
              fullWidth
              required
              id="email"
              value={email}
              label="Emailová adresa"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => {setEmail(e.target.value)}}              
            />
            <TextField
              margin="normal"
              fullWidth
              required
              multiline
              rows={10}
              id="email"
              label="Správa"
              name="message"
              autoFocus
              onChange={(e) => {setContactMessage(e.target.value)}}              
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              onClick={contactAuthor}
            >
              Odeslat
            </Button>         
          </Box>
        </Dialog>
    </Card>
  );
})
