export enum DIFFICULTY {
  TEXT = 1,
  RIDDLER = 2,
  BABY = 3
}

export const DIFFICULTY_TEXT: Map<number, string> = new Map([
  [DIFFICULTY.TEXT, "Znalec"],
  [DIFFICULTY.RIDDLER, "Hádankář"],
  [DIFFICULTY.BABY, "Mrňous"],
]);

export type Pages = "home" | "activity" | "spot" | "trip" | "help" | "about" | 
                    'luzanky' | 'account' | 'username' | 'signIn' | 'signUp' | 'password' | 'verify' | 'resetPassword' | 'admin';

export type Trip = {
  baby_carriage: boolean,
  bicycle: boolean,
  description: string,
  duration: number,
  id: number,
  length: number,
  motivation: string,
  password: string,
  short_description: string,
  timestamp_update: string,
  title: string,
  visible: boolean,
  profile_photo: {
    file_name: string,
    file_url: string
  },
  spots: number[],
  ratings: number[],
  gps: {
    lat: number,
    lng: number
  },
  author: string
  spotsAlwaysShown: boolean
};

export type File = {
  file: {
    file_name: string,
    file_url: string
  },
  id: number,
  profile_photo: boolean
};

export enum ANSWER_TYPE {
  ABCD = 1,
  TEXT = 2
}

export type Activity = {
  id_activity_type: number;
  id_answer_type: ANSWER_TYPE.ABCD;
  description: string;
  test?: {
    question: string | number;
    answer: number;
    options: {
      text: string,
      url: string
    }[];
  };
} | {
  id_activity_type: number;
  id_answer_type: ANSWER_TYPE.TEXT;
  description: string;
  test?: {
    question: string | number;
    answer: string[];
  };
};

export type Spot = {
  id: number,
  id_trip: number,
  serial_number: number,
  title: string,
  photo: {
    photo_name?: string;
    photo_file: string,
    photo_url: string
  },
  description: string,
  gps: {
    lat: number,
    lng: number
  },
  activity: { [key: number]: Activity }
};

export type Progress = {
  visited: boolean,
  activity: { [key: number]: boolean }
};

export type Rating = {
  id: number,
  id_trip: number,
  id_user: number,
  rating: number
}
