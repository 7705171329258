import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { getUserToken, sendEmail, signIn } from '../backend';
import uiStore from "../stores/ui";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const theme = createTheme();

export default function SignIn() {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
    });
  };

  React.useEffect(() => {
    uiStore.appBar = {
      page: "signIn"
    };

    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const [openReset, setOpenReset] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const [emailLog, setEmailLog] = useState('');  
  const [passwordLog, setPasswordLog] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [resetEmail, setResetEmail] = useState('');

  const [showWarning, setShowWarning] = React.useState(false);
  const [warning, setWarning] = React.useState('');

  const [message, setMessage] = React.useState('');
  const [messageColor, setMessageColor] = React.useState('white');

  const login = async () => {  
    if (emailLog == '' || passwordLog == '') {
      setWarning('Vyplňte prosím údaje.');
      setShowWarning(true);
      return;
    } 
    const response = await signIn(emailLog, passwordLog);
  
    if (response == 0) {
      setOpenSuccess(true);
    }
    else if (response == 1) {
      setWarning('Nesprávný email nebo heslo.');
      setShowWarning(true);
    }
    else {
      setWarning('Při přihlašování nastala nečakaná chyba. Prosím kontaktujte podporu LApky.');
      setShowWarning(true);
    }
  };

  const getToken = async () => {
    if (resetEmail == '') {
      setMessageColor('red');
      setMessage('E-mail nesmí být prázdný.')
      return;
    }
    const response = await getUserToken(resetEmail);
  
    if (response.result == 0) {
      const header = 'LApka - Reset hesla'
      const message = `<p>Dobrý den,</p> 
                      <p>Pro resetnutí hesla prosím klikněte na nasledující link:</p>
                      <a href="` + window.location.origin + `/resetPassword/` + response.token + `">Potvrdit e-mail</a>
                      <p style="margin-bottom:0;">
                          S pozdravem,
                      </p>
                      <p style="margin : 0; padding-top:0;">
                          LApka Team
                      </p>`;
      sendEmail(process.env.EMAIL_LOGIN || '', resetEmail, message, header);
      setMessageColor('green');
      setMessage('E-mail odeslán.');
    }
    else if (response.result == 1) {
      setMessageColor('red');
      setMessage('Účet se zadaným e-mailem neexistuje.');
    }
    else {
      setMessageColor('red');
      setMessage('Při resetu hesla nastala nečekaná chyba. Prosím kontaktujte podporu LApky.');
    }
  };

  return (
    <Box sx={{
        padding: '10px',
        '& > div': {
        marginBottom: '10px',
        },
    }}>
      <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >          
          <img alt="" style={{ width: '90%' }} src="/images/lapka_logo.png" />          
          <Typography component="h1" variant="h5">
            Přihlásit se
          </Typography>
          { showWarning ? <Typography sx={{ mt: 1}} align='center' color='red'>
            {warning}
          </Typography> : null }
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Emailová adresa"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => {setEmailLog(e.target.value)}}              
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Heslo"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              onChange={(e) => {setPasswordLog(e.target.value)}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={login}
            >
              Přihlásit
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" onClick={() => setOpenReset(true)}>
                  Zapoměli jste heslo?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2" component={RouterLink} to="/signUp">
                  Nová registrace
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Dialog open={openReset} PaperProps={{ sx: { width: "80%", height: "40%" } }} onClose={() => setOpenReset(false)}>
          <Box
            sx={{
              padding: '10px',
              marginTop: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography align='center' color={messageColor} variant="subtitle2">
              {message}
            </Typography>
            <Typography variant="h5">
              Reset hesla
            </Typography>  
            <Typography variant="subtitle2" align='center'>
              Zadejte prosím e-mail s kterým jste se registrovali do aplikace LApka.
            </Typography> 
            <TextField
              margin="normal"
              fullWidth
              required
              id="email"
              label="Emailová adresa"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => {setResetEmail(e.target.value)}}              
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              onClick={getToken}
            >
              Vyžádat reset hesla
            </Button>         
          </Box>
        </Dialog>
        <Dialog open={openSuccess} PaperProps={{ sx: { width: "80%"} }}>
          <Box
            sx={{
              marginTop: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CheckCircleOutlineIcon color='success' sx={{ fontSize: 70 }} />
            <Typography variant="h5" align='center'>
              Přihlášení proběhlo úspěšně.
            </Typography>  
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              onClick={() => navigate('/home/list')}
            >
              OK
            </Button>         
          </Box>
        </Dialog>
      </Container>
    </ThemeProvider>
    </Box >
    
  );
}