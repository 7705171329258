import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useState } from 'react';
import appStore from '../../stores/app';
import { useContext } from 'react';
import { FormContext } from '../../FormContext';
import { useNavigate } from 'react-router';
import { initialState } from '../../stores/admin';
import HtmlEditor from '../../htmlTextEditor';
// @ts-expect-error React-leaflet's typedefs are too new
import { Map, TileLayer, Marker } from "react-leaflet";
import { LatLng, Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Box } from '@mui/material';
import axios from 'axios';

export default function SpotForm({spotId, newSpot} : {spotId: number, newSpot: boolean}) {
    const spot = appStore.trips.spot[spotId];

    const formContext = useContext(FormContext);

    if (!formContext) {
        throw new Error('You tried to use FormContext outside of its provider.');
    }

    const { formState, dispatch } = formContext;

    const navigate = useNavigate();

    const icon = new Icon({
        iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        iconSize: [25, 41], // size of the icon
        shadowSize: [41, 41], // size of the shadow
        iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
        shadowAnchor: [12, 41],  // the same for the shadow
        popupAnchor: [1, -34] // point from which the popup should open relative to the iconAnchor
      });
      

    React.useEffect(() => {
        dispatch({
            type: 'SET_SPOT_ATTR',
            payload: newSpot ? { ...formState.spotAttr, id: Date.now() } : {
                id: spotId,
                title: formState.spotAttr.title ? formState.spotAttr.title : (spot?.title || ''),   
                serialNumber: formState.spotAttr.serialNumber ? formState.spotAttr.serialNumber : (spot?.serial_number?.toString() || ''),
                photoUrl: formState.spotAttr.photoUrl ? formState.spotAttr.photoUrl : (spot?.photo?.photo_url || ''),
                description: formState.spotAttr.description ? formState.spotAttr.description : (spot?.description || ''),
                gps: {
                    lng: formState.spotAttr.gps.lng ? formState.spotAttr.gps.lng : (spot?.gps?.lng?.toString() || ''),
                    lat: formState.spotAttr.gps.lat ? formState.spotAttr.gps.lat : (spot?.gps?.lat?.toString() || ''),
                },
                activitiesAttr: formState.spotAttr.activitiesAttr,
            }
        });
    }, [spot, newSpot]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
    
        if (name === 'gpsLng' || name === 'gpsLat') {
            dispatch({
              type: "UPDATE_SPOT_ATTR",
              payload: { gps: {...formState.spotAttr.gps, [name === 'gpsLng' ? 'lng' : 'lat']: value } },
            });
        } else {
            dispatch({
              type: "UPDATE_SPOT_ATTR",
              payload: { [name]: value },
            });
        }
    };    

    const handleMapClick = (e: {latlng: LatLng}) => {
        dispatch({
            type: "UPDATE_SPOT_ATTR",
            payload: { 
                gps: {
                    lng: e.latlng.lng.toString(),
                    lat: e.latlng.lat.toString(),
                } 
            },
        });
    };

    // Async function to upload a base64 image and return the image URL
    const handleUploadBase64 = async (base64: string) => {
        const responseUrl = '';
    
        // split the base64 string
        const base64Parts = base64.split(';');
        const imgFormat = base64Parts[0].split(':')[1]; // get the image format (png, jpeg, jpg)
        const imgData = base64Parts[1].split(',')[1]; // get the actual base64 data
    
        // Convert base64 to raw binary data
        console.log(imgData);
        try {
            const raw = window.atob(imgData.slice(0, -1));
            const rawLength = raw.length;
            const uInt8Array = new Uint8Array(rawLength);
        
            for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
            }
        
            // Create a blob from the byte array
            const blob = new Blob([uInt8Array], {type: imgFormat});
        
            const formData = new FormData();
            const fileExtension = imgFormat.split('/')[1]; // e.g. 'image/png' -> 'png'
            formData.append('file', blob, `filename.${fileExtension}`);
            console.log("formData: " + formData);
        
            try {
            const response = await axios.post('/upload', formData, {
                headers: {
                'Content-Type': 'multipart/form-data',
                },
            });
            return response.data; // The returned URL of the saved file
            } catch (error) {
            console.error("Error uploading image: ", error);
            }
            return responseUrl;

        } catch (e) {
            console.error("Decoding error: ", e);
        }   
    };

    const handleHtmlPictures = async () => {
        // Check for base64 images in the description, upload them, and replace with URLs
        const imgDataRegex = /<img[^>]+src="data:image\/(png|jpg|jpeg|gif);base64,[^">]+"/g;
        let description = formState.spotAttr.description;
        let newDesc = description;

        // Find all image data URLs
        const matches = description.match(imgDataRegex);
        if (matches) {
            for (let i = 0; i < matches.length; i++) {
                const imgTag = matches[i];
                const base64Match = imgTag.match(/"data:image\/(png|jpg|jpeg|gif);base64,([^"]+)"/);
                if (base64Match) { // Check if the match was successful
                    const base64 = base64Match[0];
                    
                    // Upload image and get URL
                    const url = await handleUploadBase64(base64);
              
                    // Replace base64 image data in the description with URL                          
                    newDesc = newDesc.replace(base64, url);                    
                  }
            }
        }
        console.log(newDesc);
        return newDesc;
    }
  

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (event.currentTarget.reportValidity()) {
            // Check if gps coordinates are numeric values
            if ((!(!isNaN(parseFloat(formState.spotAttr.gps.lat)) && isFinite(parseFloat(formState.spotAttr.gps.lat))) ||
                !(!isNaN(parseFloat(formState.spotAttr.gps.lat)) && isFinite(parseFloat(formState.spotAttr.gps.lat))))) {
                    window.alert("Vyplněné souřadnice nejsou číselné hodnoty.")
            }
            // Check if serial number is a positive integer
            else if (!(Number.isInteger(Number(formState.spotAttr.serialNumber)) && Number(formState.spotAttr.serialNumber) > 0)) {
                window.alert("Pořadí stanoviště musí být celé čislo.")
            }
            else if (formState.spotAttr.photoUrl == '') {
                window.alert("Profilová fotka není nahrána - pro nahrání fotografie prosím klikněte na tlačidlo upload.")
            }
            else {
                const newDesc = await handleHtmlPictures();

                const newSpotAttr = {
                    ...formState.spotAttr,
                    description: newDesc
                };
                dispatch({
                    type: 'ADD_SPOT',
                    payload: newSpotAttr
                });
                console.log("final");
                console.log(formState);
                dispatch({
                    type: 'SET_SPOT_ATTR',
                    payload: initialState.spotAttr
                });                
                navigate(-1);
            }            
        } else {
            console.log('Some required fields are empty');
        }
    };

    const [file, setFile] = useState<File | null>(null); // state to store uploaded file

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0]; 
            const fileType = file["type"];
            const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    
            if (validImageTypes.includes(fileType)) {
                // Valid image file
                setFile(file); // store file in state
            } else {
                // Invalid image file
                alert("Please select an image file: .jpeg, .jpg, or .png");
            }
        }
    };

    const handleUpload = async () => {
        if (file) {
          const formData = new FormData();
          formData.append('file', file);
      
          try {
            const response = await axios.post('/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
      
            const url = response.data; // The returned URL of the saved file
      
            dispatch({
              type: "UPDATE_SPOT_ATTR",
              payload: { ['photoUrl']: url },
            });

          } catch (error) {
            console.error("Error uploading image: ", error);
          }
        }
      };

    return (
        <React.Fragment>     
            <form onSubmit={handleSubmit}>   
                <Grid container spacing={6}>
                <Grid item xs={12} >
                    <TextField
                    required
                    fullWidth
                    name="title"
                    value={formState.spotAttr.title}
                    label="Jméno stanoviště - musí být unikátní"
                    onChange={handleChange} 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    fullWidth
                    required
                    name='gpsLng'
                    value={formState.spotAttr.gps.lng}
                    label="GPS_lng"
                    type="number"
                    onChange={handleChange} 
                    />
                    <TextField
                    fullWidth
                    required
                    name='gpsLat'
                    value={formState.spotAttr.gps.lat}
                    label="GPS_lat"
                    type="number"
                    onChange={handleChange} 
                    sx={{mt: 6}}
                    />
                    <TextField
                    required
                    label="Sériové číslo"
                    fullWidth
                    type="number"
                    name='serialNumber'
                    value={formState.spotAttr.serialNumber}
                    onChange={handleChange} 
                    sx={{mt: 6}}
                    />
                    <Box sx={{ mt: 6 }} >
                        <label style={{ display: 'block' }}>Profilová fotka stanoviště</label>
                        <input
                        accept=".jpeg,.jpg,.png"
                        type="file"
                        onChange={handleFileChange}                        
                        />
                        <Button onClick={handleUpload}>Upload</Button>
                        {formState.spotAttr.photoUrl != '' && <p>Selected file: {formState.spotAttr.photoUrl.split('/').pop()}</p>}                        
                    </Box>
                    
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Map style={{ height: "365px", width: "100%" }} center={[formState.spotAttr.gps.lat || 49.195, formState.spotAttr.gps.lng || 16.608]} zoom={18} onClick={handleMapClick}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker position={[formState.spotAttr.gps.lat || 49.195, formState.spotAttr.gps.lng || 16.608]} icon={icon} />
                    </Map>
                </Grid>  
                <Grid item xs={12}>
                    <label>Popis stanoviště</label>
                    <HtmlEditor 
                        name='description'
                        type='UPDATE_SPOT_ATTR'
                    />
                </Grid>                                    
                </Grid>
                <Button 
                    type='submit'  
                    sx={{mt: 8}}                  
                >
                    Uložit
                </Button>
            </form>    
        </React.Fragment>
    );
}