import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useParams } from "react-router-dom";
import SpotForm from './SpotForm';
import './admin-dashboard.css';
import Activities from './Activities';

export default function SpotEdit() {

  const { id } = useParams();
  const spotId = parseInt(id || '', 10);
  const newSpot = spotId ? false : true;

  return (
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            {/* Spot Information */}
            <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <SpotForm spotId={spotId} newSpot={newSpot} />
                </Paper>
            </Grid>
            {/* Activities*/}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Activities spotId={spotId} />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
  );
}
