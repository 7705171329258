import { action, computed, configure, makeAutoObservable, observable } from "mobx";
import { Pages } from "../types";

export class UIStore {
  constructor() {
    makeAutoObservable(this, {}, {
      autoBind: true,
    });
  }
  

  @observable
  public appLoading = true;

  @observable
  public tripLoading = false;

  @observable
  public online = navigator.onLine;

  @observable
  public connectionError = false;

  @observable
  public gpsAvailable = true;

  @observable
  public downloadCachePending = false;

  @observable
  public scrolledBottom = false;

  @observable
  public currentUserPosition: {
    lat: number,
    lng: number,
    accuracy: number
  } | null = null;

  @observable
  public appBar: {
    page: Pages,
    id?: string
  } = { page: 'home' };

  @observable
  public scroll: { [key in Pages]: number; } = {
    home: 0,
    activity: 0,
    spot: 0,
    trip: 0,
    help: 0,
    about: 0,
    luzanky: 0,
    account: 0,
    username: 0,
    password: 0,
    signIn: 0,
    signUp: 0,
    verify: 0,
    resetPassword: 0,
    admin: 0
  };

  @observable
  public reachedSpot: number | null = null;

  @observable
  public ignoredReachedSpots: { [key: string]: boolean } = {};

  @observable
  public finishedTrip: number | null = null;

  @computed
  public hasConnectionIssue() {
    return !this.online || this.connectionError;
  }

  @action
  public closeReachedSpotPopup() {
    if (this.reachedSpot) {
      this.ignoredReachedSpots[this.reachedSpot] = true;
    }
  }

  @action
  public handleScroll() {
    const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
          const windowHeight = window.innerHeight;
          const documentHeight = document.documentElement.scrollHeight;
  
    if (scrollTop + windowHeight >= documentHeight - 50) {
    this.scrolledBottom = true;
    } else {
    this.scrolledBottom = false;
    }
  }
}

configure({
  enforceActions: 'never',
});
export const uiStore = new UIStore();

function updateOnlineStatus() {
  uiStore.online = !!navigator.onLine;
}

window.addEventListener("online", updateOnlineStatus);
window.addEventListener("offline", updateOnlineStatus);

export default uiStore;
