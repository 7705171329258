import * as React from "react";
import Popup from "./Popup";
import uiStore from "../stores/ui";
import appStore from "../stores/app";
import { getDistance } from "../utils";
import { DISTANCE_OFFSET } from "../constants";
import NewStopAvailablePopup from "../popups/NewStopAvailablePopup";
import { observer } from "mobx-react-lite";
import TripFinishedPopup from "../popups/TripFinishedPopup";

export default observer(function GeoListener({ tripId }: { tripId: number; }) {
  React.useEffect(() => {
    uiStore.reachedSpot = null;
    uiStore.finishedTrip = null;

    const watch = navigator.geolocation.watchPosition(
      ({ coords: { latitude: lat, longitude: lng, accuracy } }) => {
        uiStore.gpsAvailable = true;
        uiStore.currentUserPosition = { lat, lng, accuracy };

        if (accuracy > 100) return;

        const candidateSpots = appStore.trips.collection[tripId].spots.flatMap(spotId => {
          if (!!uiStore.ignoredReachedSpots[spotId] || appStore.trips.progress[spotId]?.visited) {
            return [];
          }
          const { id, gps } = appStore.trips.spot[spotId];
          const distance = getDistance(gps, { lat, lng });
          if (distance >= accuracy + DISTANCE_OFFSET) {
            return [];
          }
          return [{ id, distance, accuracy }];
        });

        candidateSpots.sort((a, b) => b.distance - a.distance);
        const reachedSpot = candidateSpots[0];

        if (reachedSpot) {
          uiStore.reachedSpot = reachedSpot.id;
          appStore.spotVisited(reachedSpot.id);
          if (appStore.trips.collection[tripId].spots.every(spotId => appStore.isSpotVisited(spotId))) {
            uiStore.finishedTrip = tripId;
          }
        }
      },
      () => {
        uiStore.gpsAvailable = false;
      },
      {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 27000
      }
    );
    return () => navigator.geolocation.clearWatch(watch);
  }, [tripId]);

  return (
    <div>
      <Popup
        open={!!uiStore.reachedSpot && !uiStore.finishedTrip && !uiStore.ignoredReachedSpots[uiStore.reachedSpot]}
        data={{ onClose: uiStore.closeReachedSpotPopup }}
        content={NewStopAvailablePopup}
      />
      <Popup
        open={!!uiStore.reachedSpot && !!uiStore.finishedTrip && !uiStore.ignoredReachedSpots[uiStore.reachedSpot]}
        data={{ onClose: uiStore.closeReachedSpotPopup }}
        content={TripFinishedPopup}
      />
    </div>
  );
});
