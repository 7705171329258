import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";

export default function SubMenu({ items }: {
  items: { [route: string]: string }
}) {
  const { pathname } = useLocation()
  const ix = Object.keys(items).findIndex(a => pathname.includes(a));
  return (
    <Tabs value={ix >= 0 ? ix : 0}>
      {Object.entries(items).map(([route, title]) => (
        <Tab key={route} component={Link} to={route} label={title} />
      ))}
    </Tabs>
  );
}
