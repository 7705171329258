import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import * as React from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import uiStore from "../../stores/ui";
import './admin-dashboard.css';
import { AppBar } from "@mui/material";
import { useContext } from "react";
import { FormContext } from "../../FormContext";
import { initialState } from "../../stores/admin";
import appStore from "../../stores/app";

const mdTheme = createTheme();

export default function Dashboard() {

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!appStore.admin) {
      navigate('/');
    }
  }, [navigate]);

  let title: string = "Trasy";

  if (window.location.pathname.includes("editSpot")) {
    title = "Úprava stanoviště"
  } else if (window.location.pathname.includes("editTrip")) {
    title = "Úprava trasy"
  } else if (window.location.pathname.includes("editActivity")) {
    title = "Úprava aktivity"
  }

  const formContext = useContext(FormContext);
  if (!formContext) {
      throw new Error('You tried to use FormContext outside of its provider.');
    }
  const { formState, dispatch } = formContext;

  const goBack = React.useCallback(() => {
    const message = "Máte neuložené změny. Chcete pokračovat?";
  
    const navigateBack = (dispatchType: any, payload: any) => {
      const leave = window.confirm(message);
      if (leave) {
        dispatch({ type: dispatchType, payload });
        navigate(-1);
      }
    }
  
    if (window.location.pathname.includes("editActivity")) {
      navigateBack('SET_ACTIVITY_ATTR', initialState.activityAttr);
    } else if (window.location.pathname.includes("editSpot")) {
      navigateBack('SET_SPOT_ATTR', initialState.spotAttr);
    } else if (window.location.pathname.includes("editTrip")) {
      dispatch({ type: 'CLEAR_SPOTS' });
      navigateBack('SET_TRIP_ATTR', initialState.tripAttr);
    } else if (window.location.pathname.includes("admin/trips")) {
      navigate('/home/list');
    }
  }, [navigate]);
  

  React.useEffect(() => {
    uiStore.appBar = {
      page: "admin"
    };

    document.documentElement.setAttribute('data-admin-dashboard', 'true');
    window.scrollTo(0, uiStore.scroll.admin);
    return () => {
      document.documentElement.removeAttribute('data-admin-dashboard');
      uiStore.scroll.admin = window.scrollY;
    };
  }, []);

  return (
    <div className='admin-dashboard'>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="fixed">
            <Toolbar sx={{width: '100%'}}>
              <IconButton
                style={{ marginLeft: -12, marginRight: 20 }}
                color="inherit"
                aria-label="Menu"
                onClick={goBack}
                size="large">
                <ArrowBackIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                {title}
              </Typography>
            </Toolbar>
          </AppBar> 
          <Outlet />         
        </Box>
      </ThemeProvider>
    </div>    
  );
}
