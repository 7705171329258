import * as React from "react";
import { CardHeader, IconButton, Tooltip, Card, CardContent, CardActionArea, CardMedia, Box } from "@mui/material";
import { Link } from "react-router-dom";
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import ChildIcon from "@mui/icons-material/ChildFriendly";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WalkIcon from "@mui/icons-material/DirectionsWalk";
import LockIcon from "@mui/icons-material/Lock";
import DoneIcon from "@mui/icons-material/CheckCircle";
import StarIcon from "@mui/icons-material/Star";
import GetAppIcon from "@mui/icons-material/GetApp";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import appStore from "../stores/app";
import { CachedImage } from "./CachedImage";
import { observer } from "mobx-react-lite";

export const TripCard = observer(function TripCard({ tripId }: { tripId: number }) {
  const trip = appStore.trips.collection[tripId];

  const averageRating = appStore.getTripRatingAverage(tripId);
  const ratingCount = trip.ratings.length;

  const isLocked = trip.password && !appStore.trips.unlocked[tripId];
  const isVisited = appStore.getTripScoreAvailableForVote(tripId);
  const cached = appStore.trips.cached[tripId];

  return (
    <Card sx={{
      marginBottom: '20px',
      opacity: isLocked ? 0.7 : 1,
      backgroundColor: isLocked ? '#ccc' : undefined,
    }}>
      <CardActionArea component={Link} to={`/trip/${tripId}/description`}>
        {isLocked && (
          <LockIcon
            fontSize="large"
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              color: 'white',
            }}
          />
        )}

        {isVisited && (
          <DoneIcon
            fontSize="large"
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              color: 'white',
            }}
          />
        )}

        <CachedImage
          image={trip.profile_photo.file_url}
          cacheName={`trip-${tripId}`}
        >
          {image => <CardMedia
            style={{ height: 140 }}
            image={image}
            title={trip.profile_photo.file_name}
          />}
        </CachedImage>

        <CardHeader
          title={trip.title.toUpperCase()}
          subheader={trip.short_description}
          action={
            <Tooltip placement="top" title="" enterTouchDelay={1}>
              {cached ? (
                <OfflinePinIcon sx={{
                  paddingTop: '10px',
                  paddingRight: '10px',
                  color: 'gray',
                }} />
              ) : (
                <GetAppIcon sx={{
                  paddingTop: '10px',
                  paddingRight: '10px',
                  color: 'gray',
                }} />
              )}
            </Tooltip>
          }
        />        
      </CardActionArea> 

      <CardContent sx={{ display: "flex", padding: 0 }}>

      { ratingCount >= 5 && 
        <Tooltip
          placement="top"
          title="Hodnocení"
          enterTouchDelay={1}
        >
          <IconButton aria-label="Star" size="large" >
            <StarIcon fontSize="small" />
            <span style={{ fontSize: 18 }}>
              {" "}
              &nbsp;
              {averageRating}
            </span>
          </IconButton>
        </Tooltip> }

        <Tooltip
          placement="top"
          title="Časový odhad náročnosti cesty"
          enterTouchDelay={1}
        >
          <IconButton aria-label="Čas" size="large">
            <AccessTimeIcon fontSize="small" />
            <span style={{ fontSize: 18 }}>
              {" "}
              &nbsp;
              {trip.duration}
              hod.
            </span>
          </IconButton>
        </Tooltip>        

        <Tooltip
          placement="top"
          title="Přibližná délka celé cesty"
          enterTouchDelay={1}
        >
          <IconButton aria-label="Vzdálenost" size="large">
            <WalkIcon fontSize="small" />
            <span style={{ fontSize: 18 }}>
              {" "}
              &nbsp;
              {trip.length}
              km
            </span>
          </IconButton>
        </Tooltip>

        {trip.baby_carriage ? (
          <Tooltip
            placement="top"
            title="Vhodné pro kočárky"
            enterTouchDelay={1}
          >
            <IconButton aria-label="Child" size="large">
              <ChildIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : ''}

        {trip.bicycle ? (
          <Tooltip
            placement="top"
            title="Vhodné pro jízdu na kole"
            enterTouchDelay={1}
          >
            <IconButton aria-label="Bike" size="large">
              <DirectionsBikeIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : ''}
      </CardContent>
    </Card>
  );
});
