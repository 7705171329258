import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import appStore from '../../stores/app';
import { useReducer } from 'react';
import { ActivityAttributes, State, TripAttributes, initialState } from '../../stores/admin';
import { useContext } from 'react';
import { FormContext } from '../../FormContext';
import { getTrips, setTrip } from '../../backend';
import { useNavigate } from 'react-router';
import HtmlEditor from '../../htmlTextEditor';
import { Box } from '@mui/material';
import axios from 'axios';
import { ANSWER_TYPE } from '../../types';

export default function TripForm({tripId, newTrip} : {tripId: number, newTrip: boolean}) {

    const formContext = useContext(FormContext);
    if (!formContext) {
        throw new Error('You tried to use FormContext outside of its provider.');
      }
    const { formState, dispatch } = formContext;

    const navigate = useNavigate();

    const currTrip = appStore.trips.collection[tripId];

    const tripSpots = newTrip ? [] : appStore.trips.collection[tripId].spots;

    React.useEffect(() => {
        dispatch({
            type: 'SET_TRIP_ATTR',
            payload: newTrip ? { ...formState.tripAttr, id: Date.now() } : {
                    id: tripId,
                    title: formState.tripAttr.title ? formState.tripAttr.title : (currTrip.title || ''),
                    author: formState.tripAttr.author ? formState.tripAttr.author : (currTrip.author || ''),
                    shortDesc: formState.tripAttr.shortDesc ? formState.tripAttr.shortDesc : (currTrip.short_description || ''),
                    motivation: formState.tripAttr.motivation ? formState.tripAttr.motivation : (currTrip.motivation || ''),
                    description: formState.tripAttr.description ? formState.tripAttr.description : (currTrip.description || ''),
                    length: formState.tripAttr.length ? formState.tripAttr.length : (currTrip.length || ''),
                    duration: formState.tripAttr.duration ? formState.tripAttr.duration : (currTrip.duration || ''),
                    secretPassword: formState.tripAttr.secretPassword ? formState.tripAttr.secretPassword : (atob(currTrip.password) || ''),
                    profileUrl: formState.tripAttr.profileUrl ? formState.tripAttr.profileUrl : (currTrip.profile_photo.file_url || ''),
                    publicTrip: formState.tripAttr.publicTrip ? true : (currTrip.visible || false),
                    spotsShown: formState.tripAttr.spotsShown ? true : (currTrip.spotsAlwaysShown || false),
                    bicycle: formState.tripAttr.bicycle ? true : (currTrip.bicycle || false),
                    babyCarriage: formState.tripAttr.babyCarriage ? true : (currTrip.baby_carriage || false),
                }
            }
        );
        tripSpots.map((spotId) => {
            if (formState.spotsAttr.length == 0) {
                const spot = appStore.trips.spot[spotId];
                const activitiesAttributes: ActivityAttributes[] = [];
                Array.from({ length: 3 }, (_, index) => index + 1).map((index) => {
                    const activity = appStore.getActivity(spotId, index);
                    if (activity) {
                    activitiesAttributes.push({
                        activityType: activity ? activity.id_activity_type.toString() : "1",
                        answerType: activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? "1" : "2",
                        description: activity ? activity.description : '',
                        question: activity ? (activity.test?.question || '') : '',
                        correctAnswer: activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.answer ?
                            (['A','B','C','D'])[activity.test?.answer] : '') : (activity && activity.test?.answer ? activity.test?.answer?.join("|") : ''),
                        answerA: activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[0]?.text || '') : '',
                        answerAUrl: activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[0]?.url || '') : '',
                        answerB: activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[1]?.text || '') : '',
                        answerBUrl: activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[1]?.url || '') : '',
                        answerC: activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[2]?.text || '') : '',
                        answerCUrl: activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[2]?.url || '') : '',
                        answerD: activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[3]?.text || '') : '',
                        answerDUrl: activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[3]?.url || '') : ''
                    });
                    }
                });
                dispatch({
                    type: 'SET_SPOT_AND_ADD',
                    payload: {
                        id: spotId,
                        title: spot?.title || '',   
                        serialNumber: spot?.serial_number?.toString() || '',
                        photoUrl: spot?.photo?.photo_url || '',
                        description: spot?.description || '',
                        gps: {
                            lng: spot?.gps?.lng?.toString() || '',
                            lat: spot?.gps?.lat?.toString() || '',
                        },
                        activitiesAttr: activitiesAttributes,
                    }
                }); 
            }      
        })
    }, [dispatch, tripId, newTrip, currTrip]);

    const handleInputChange = (field: keyof TripAttributes) => (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        dispatch({
          type: 'UPDATE_TRIP_ATTR',
          payload: {
            [field]: event.target.value,
          },
        });
        console.log(formState);
      };
      
      const handleCheckboxChange = (field: keyof TripAttributes) => (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        dispatch({
          type: 'UPDATE_TRIP_ATTR',
          payload: {
            [field]: event.target.checked,
          },
        });
      }; 

     // Async function to upload a base64 image and return the image URL
     const handleUploadBase64 = async (base64: string) => {
        const responseUrl = '';
    
        // split the base64 string
        const base64Parts = base64.split(';');
        const imgFormat = base64Parts[0].split(':')[1]; // get the image format (png, jpeg, jpg)
        const imgData = base64Parts[1].split(',')[1]; // get the actual base64 data
    
        // Convert base64 to raw binary data
        try {
            const raw = window.atob(imgData.slice(0, -1));
            const rawLength = raw.length;
            const uInt8Array = new Uint8Array(rawLength);
        
            for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
            }
        
            // Create a blob from the byte array
            const blob = new Blob([uInt8Array], {type: imgFormat});
        
            const formData = new FormData();
            const fileExtension = imgFormat.split('/')[1]; // e.g. 'image/png' -> 'png'
            formData.append('file', blob, `filename.${fileExtension}`);
        
            try {
            const response = await axios.post('/upload', formData, {
                headers: {
                'Content-Type': 'multipart/form-data',
                },
            });
            return response.data; // The returned URL of the saved file
            } catch (error) {
            console.error("Error uploading image: ", error);
            }
            return responseUrl;

        } catch (e) {
            console.error("Decoding error: ", e);
        }   
    };

    const handleHtmlPictures = async () => {
        // Check for base64 images in the description, upload them, and replace with URLs
        const imgDataRegex = /<img[^>]+src="data:image\/(png|jpg|jpeg|gif);base64,[^">]+"/g;
        let description = formState.tripAttr.description;
        let newDesc = description;

        // Find all image data URLs
        const matches = description.match(imgDataRegex);
        if (matches) {
            for (let i = 0; i < matches.length; i++) {
                const imgTag = matches[i];
                const base64Match = imgTag.match(/"data:image\/(png|jpg|jpeg|gif);base64,([^"]+)"/);
                if (base64Match) { // Check if the match was successful
                    const base64 = base64Match[0];
                    
                    // Upload image and get URL
                    const url = await handleUploadBase64(base64);
                    console.log(url);
              
                    // Replace base64 image data in the description with URL                          
                    newDesc = newDesc.replace(base64, url);                    
                  }
            }            
        }    
        console.log('newDesc')
        console.log(newDesc);
        return newDesc;    
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (event.currentTarget.reportValidity()) {
            if ((!(!isNaN(parseFloat(formState.tripAttr.length)) && isFinite(parseFloat(formState.tripAttr.length))) ||
                !(!isNaN(parseFloat(formState.tripAttr.duration)) && isFinite(parseFloat(formState.tripAttr.duration))))) {
                    window.alert("Vyplněné hodnoty vzdálenosti a doby trvání nejsou číselné hodnoty.");
            } 
            else if (formState.spotsAttr.length == 0) {
                window.alert("Trasa musí obsahovat alespoň jedno stanoviště.")
            }
            else if (formState.tripAttr.profileUrl == '') {
                window.alert("Profilová fotka není nahrána - pro nahrání fotografie prosím klikněte na tlačidlo upload.")
            }
            else { 
                const newDesc = await handleHtmlPictures();

                const newState: State = {
                    tripAttr: {
                        ...formState.tripAttr,
                        description: newDesc
                    },
                    spotAttr: {
                        ...formState.spotAttr                        
                    },
                    spotsAttr: [
                        ...formState.spotsAttr
                    ],
                    activityAttr: {
                        ...formState.activityAttr
                    }
                }
                
                console.log(newState);               
                await setTrip(newState);
                window.alert("Trasa úspěšne změnena.");
                dispatch({
                    type: 'SET_TRIP_ATTR',
                    payload: initialState.tripAttr
                });
                dispatch({ type: 'CLEAR_SPOTS' });
                await getTrips();                
                navigate("/admin/trips");
            }               
        } else {
          console.log('Vyplňte prosím všechny povinné části.');
        }
      };

    const [file, setFile] = useState<File | null>(null); // state to store uploaded file

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0]; 
            const fileType = file["type"];
            const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    
            if (validImageTypes.includes(fileType)) {
                // Valid image file
                setFile(file); // store file in state
            } else {
                // Invalid image file
                alert("Please select an image file: .jpeg, .jpg, or .png");
            }
        }
    };

    const handleUpload = async () => {
        if (file) {
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            const response = await axios.post('/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            });
    
            const url = response.data; // The returned URL of the saved file
    
            dispatch({
                type: 'UPDATE_TRIP_ATTR',
                payload: {
                  ['profileUrl']: url,
                },
              });

        } catch (error) {
            console.error("Error uploading image: ", error);
        }
        }
    };

    return (        
        <React.Fragment>     
            <form onSubmit={handleSubmit}>   
                <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    fullWidth
                    value={formState.tripAttr.title}
                    label="Jméno trasy - musí být unikátní"
                    onChange={handleInputChange('title')} 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    fullWidth
                    value={formState.tripAttr.author}
                    label="Emailová adresa autora"
                    onChange={handleInputChange('author')} 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    label="Krátký popis zobrazený v karte seznamu cest"
                    fullWidth
                    multiline
                    rows={6}
                    value={formState.tripAttr.shortDesc}
                    onChange={handleInputChange('shortDesc')} 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    label="Motivace trasy zobrazená nad hlavním popisem."
                    fullWidth
                    multiline
                    rows={6}
                    value={formState.tripAttr.motivation}
                    onChange={handleInputChange('motivation')} 
                    />
                </Grid>
                <Grid item xs={12}>
                    <label>Popis trasy</label>
                    <HtmlEditor 
                        name='description'
                        type='UPDATE_TRIP_ATTR'
                    />
                </Grid>
                <Grid item xs={12} sm={3} sx={{mt: 6}}>
                    <TextField
                    required
                    label="Délka trasy v km, napr 4.5(km)"
                    fullWidth
                    type='number'
                    value={formState.tripAttr.length}
                    onChange={handleInputChange('length')} 
                    />
                </Grid>
                <Grid item xs={12} sm={3} sx={{mt: 6}}>
                    <TextField
                    required
                    label="Doba trvání trasy v hodinách, napr 1.5(h)"
                    fullWidth
                    type='number'
                    value={formState.tripAttr.duration}
                    onChange={handleInputChange('duration')} 
                    />
                </Grid>
                <Grid item xs={12} sm={6} sx={{mt: 6}}>
                    <TextField
                    label="Heslo pro odemknutí trasy"
                    fullWidth
                    value={formState.tripAttr.secretPassword}
                    onChange={handleInputChange('secretPassword')} 
                    />
                </Grid>   
                <Grid item xs={12}>
                    <Box sx={{ mt: 6 }} >
                        <label style={{ display: 'block' }}>Profilová fotka trasy</label>
                        <input
                        type="file"
                        accept=".jpeg,.jpg,.png"
                        onChange={handleFileChange}                        
                        />
                        <Button onClick={handleUpload}>Upload</Button>
                        {formState.tripAttr.profileUrl != '' && <p>Selected file: {formState.tripAttr.profileUrl.split('/').pop()}</p>}
                    </Box>
                </Grid>              
                <Grid item xs={12} sm={3}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={formState.tripAttr.publicTrip}
                            onChange={handleCheckboxChange('publicTrip')}
                        />}
                        label="Veřejná trasa"
                    />                    
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={formState.tripAttr.spotsShown}
                            onChange={handleCheckboxChange('spotsShown')}
                        />}
                        label="Zobrazit vždy všechny body"
                    />                    
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={formState.tripAttr.bicycle}
                            onChange={handleCheckboxChange('bicycle')}
                        />}
                        label="Vhodná pro kolo"
                    />                    
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={formState.tripAttr.babyCarriage}
                            onChange={handleCheckboxChange('babyCarriage')}
                        />}
                        label="Vhodná pro kočík"
                    />                    
                </Grid>                
                </Grid>
                <Button 
                    type='submit'                    
                >
                    Uložit
                </Button>
            </form>  
        </React.Fragment>
    );
}