import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BadgeIcon from '@mui/icons-material/Badge';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import { Box, Card, CardContent, Grid, Stack, Typography, CircularProgress } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import appStore from '../stores/app';
import uiStore from "../stores/ui";
import TripStat from '../components/TripStat';

export default function Account() {

  React.useEffect(() => {
      uiStore.appBar = {
        page: "account"
      };
  
      window.scrollTo(0, 0);
    }, []);

  const tripsWithProgress: number[] = [];

  if (appStore.trips.list) {
    appStore.trips.list.forEach((tripId) => {
      const trip = appStore.trips.collection[tripId];
      const hasProgress = trip.spots.some((spotId) => appStore.trips.progress[spotId]?.visited);
  
      if (hasProgress) {
        tripsWithProgress.push(tripId);
      }
    });
  }

  return (
  <Box sx={{
      padding: '10px',
      '& > div': {
      marginBottom: '10px',
      },
    }}>
    <Card>
      <CardContent>
        <h2>Osobní údaje</h2>

        <Grid container sx={{ pt: 1, borderBottom: 1, flexDirection: 'row', justifyContent: 'space-between' }} color='#006bcc'>
          <Stack direction="row" alignItems="center" gap={1}>
              <EmailIcon sx={{fontSize: "1.2rem"}}/>
              <Typography 
                  component="span"
                  sx={{
                      fontSize: 18,
                      fontWeight: 600
                  }}>Email
              </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
              <Typography 
                  component="span"
                  color='#A3A3AF'
                  sx={{
                      fontSize: 18,
                      fontWeight: 400
                  }}>{appStore.email}
              </Typography>

          </Stack>                       
        </Grid>

        <Grid container sx={{ pt: 3, borderBottom: 1, flexDirection: 'row', justifyContent: 'space-between' }}
              color='#006bcc' 
              component={Link} to="/username">
          <Stack direction="row" alignItems="center" gap={1}>
              <BadgeIcon sx={{fontSize: "1.2rem"}}/>
              <Typography 
                  component="span"
                  sx={{
                      fontSize: 18,
                      fontWeight: 600
                  }}>Přezdívka
              </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
              <Typography 
                  component="span"
                  color='#A3A3AF'
                  sx={{
                      fontSize: 18,
                      fontWeight: 400
                  }}>{appStore.nickname}
              </Typography>
              <ArrowForwardIosIcon sx={{fontSize: "1.2rem"}} />
          </Stack>                       
        </Grid>

        <Grid container sx={{ pt: 3, borderBottom: 1, flexDirection: 'row', justifyContent: 'space-between' }}
              color='#006bcc'
              component={Link} to="/password">
          <Stack direction="row" alignItems="center" gap={1}>
              <KeyIcon sx={{fontSize: "1.2rem"}} />
              <Typography 
                  component="span"
                  sx={{
                      fontSize: 18,
                      fontWeight: 600
                  }}>Heslo
              </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <ArrowForwardIosIcon sx={{fontSize: "1.2rem"}}/>
          </Stack>                       
        </Grid>  
      </CardContent>
    </Card>
    <Card>
      <CardContent>
        <h2>Moje cesty</h2>
        {!appStore.trips.list ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={100} />
        </div>
        ) : (
          <div>
            {tripsWithProgress.map((tripId) => (
              <TripStat tripId={tripId}/>
            ))}
            {tripsWithProgress.length == 0 && <Typography
              align='center'
              sx={{ fontSize: 15, color: "#747474" }}
              >
              Vypadá to, že jste zatím nezačali žádnou trasu. Jakmile vyrazíte na cestu, zde se objeví vaše statistiky. Dobrodružství na vás už čeká!
            </Typography> }
          </div>
        )}
      </CardContent>
    </Card>
  </Box >
  );
};