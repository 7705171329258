import { Box, Card, CardContent, CircularProgress, CircularProgressProps, Divider, Typography } from '@mui/material';
import { observer } from "mobx-react-lite";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import appStore from '../stores/app';
import { calculateTotalDistance } from "../utils";

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ ml: 0.5, position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            fontSize={11}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
}



export default observer(function TripStat({tripId} : {tripId :number}) {

    const navigate = useNavigate();

    const trip = appStore.trips.collection[tripId]; 
    const { list } = appStore.getTripScore(tripId);
    const stopsVisitedAlready = list.filter(a => a).length;
    const spotsGps = appStore.trips.collection[tripId].spots.map(id => appStore.trips.spot[id].gps);

    return (
      <div style={{ padding: '5px' }}>
        <Card style={{border: '4px black'}} onClick={() => navigate(`/trip/${tripId}/description`)}>
            <CardContent sx={{ display: "flex", flexDirection: "column"}}>
                <Typography sx={{ mb: 1, fontSize: 19 }}> 
                    {trip.title} 
                </Typography>
                <Box sx={{display: "flex", alignItems: 'center', mb: -1.5}}>
                    <Typography sx={{fontSize: 14}}>Dokončeno: </Typography>
                    <CircularProgressWithLabel                     
                        value={stopsVisitedAlready/(list.length == 0 ? 1 : list.length) * 100}
                    />
                    <Typography sx={{ml: 3, fontSize: 14}}> 
                        {`Překonáno: ${Math.round(calculateTotalDistance(list, spotsGps))}m`}
                    </Typography>
                </Box>                
            </CardContent>
        </Card>
    </div>
    );
});
