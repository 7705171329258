import * as React from "react";
import Filter from '../../components/Filter';
import FilteredTripCards from "../../components/FilteredTripCards";
import appStore from "../../stores/app";

export default function List() {
  const [stroller, setStroller] = React.useState('');
  const [lock, setLock] = React.useState('');
  const [transportation, setTransportation] = React.useState('');

  const maxDuration = React.useMemo(() => {
    return appStore.getMaxDuration();
  }, [])

  const maxLength = React.useMemo(() => {
    return appStore.getMaxLength();
  }, [])

  const [duration, setDuration] = React.useState(maxDuration);
  const [length, setLength] = React.useState(maxLength);

  return (
    <div style={{ padding: '10px' }}>
      <Filter 
        stroller={stroller} 
        setStroller={setStroller}
        lock={lock}
        setLock={setLock}
        transportation={transportation}
        setTransportation={setTransportation}
        duration={duration}
        setDuration={setDuration}
        length={length}
        setLength={setLength}
        maxDuration={maxDuration}
        maxLength={maxLength}
        />
      <FilteredTripCards
        stroller={stroller} 
        lock={lock} 
        transportation={transportation} 
        duration={duration} 
        length={length}
        />
    </div>
  );
};
