import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { verifyUser } from '../backend';
import uiStore from "../stores/ui";

const theme = createTheme();

export default function Verify() {

  React.useEffect(() => {
    uiStore.appBar = {
      page: "verify"
    };
    window.scrollTo(0, 0);

    async function verifyUserBackend() {
        const response = await verifyUser(token);
        if (response == 0) {
            setSuccess(true);
        }
        else {
            setSuccess(false);
        }
    }
    verifyUserBackend();
  }, []);

  const {token} = useParams();

  const navigate = useNavigate();

  const [success, setSuccess] = useState(true);


  return (
    <Box sx={{
        padding: '10px',
        '& > div': {
        marginBottom: '10px',
        },
    }}>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
            sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            }}
        >
            {success ? <CheckCircleOutlineIcon color='success' sx={{ fontSize: 70 }} /> : <HighlightOffIcon color='error' sx={{ fontSize: 70 }} /> }
            <Typography variant="h5" align='center'>
                {success ? "E-mailová adresa úspěšne ověřena." : "Při ověřování e-mailu došlo k neočekávané chybě."}
            </Typography>  
            <Typography variant="subtitle1" align='center'>
                {success ? "Nyní se můžete přihlásit." : "Prosím kontaktujte podporu LApky."}
            </Typography> 
            <Button
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                onClick={() => navigate('/signIn')}
                >
                OK
            </Button>         
        </Box>
      </Container>
    </ThemeProvider>
    </Box >
    
  );
}