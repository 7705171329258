import { Box } from "@mui/material";
import * as React from "react";

type Props = {
  children: string,
  cacheName: string
};

export function CachedHtmlView({ children, cacheName }: Props) {
  // use the actual url as fallback in case we didn't cached it
  const baseHtml = children
    .replace(/href="([^"]*)"/, `href="$1" target="_blank"`)
    .replace(/&amp;/g, "&");

  const [html, setHtml] = React.useState(baseHtml);

  React.useEffect(() => {
    (async () => {
      try {
        const exists = await caches.has(cacheName);
        if (!exists) return;

        const cache = await caches.open(cacheName);
        await Promise.all([...html.matchAll(/src="([^"]*)"/g)].map(async ([, url]) => {
          const imageResponse = await cache.match(url);
          if (!imageResponse) return;
          const blob = await imageResponse.blob();
          const cachedUrl = URL.createObjectURL(blob);
          setHtml(html => html.replace(url, cachedUrl));
        }));
      } catch (e) {
        console.warn("CachedLayer issue", e);
      }
    })();
  }, [html, cacheName]);

  return (
    <Box
      sx={{
        'img': {
          width: '100%',
        },
      }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}
