import React from "react";
import { Box, Card, CardContent, CardActions, Button, Typography } from "@mui/material";
import uiStore from "../stores/ui";
import { observer } from "mobx-react-lite";

const ShortAboutCard = () => (
  <Card>
    <CardContent>
      <img alt="" style={{ width: '100%' }} src="/images/lapka_logo.png" />
      <Typography paragraph>
        Lapka je mobilní hravá aplikace do mobilu, která vás provede po Brně
        místy, která možná neznáte, nebo jste o nich jen něco slyšeli a zajímá
        vás to. LApka vám nabízí procházku a objevování nových míst. Pro zvídavé
        nabízí i zajímavé informace, otázky, řešení úkolů, či šifer. Ale je také
        pro malé předškoláky, kteří jdou na výlet se staršími a třeba si po
        cestě chtějí něco zahrát. LApka je pro každého, malého, velkého, s
        kočárkem, na koloběžce či pěšky. Pojďte ji zkusit :-)
      </Typography>
      <Typography paragraph>
        © Aplikaci LApka vytvořily{" "}
        <a href="https://www.luzanky.cz/" target="_blank" rel="noopener noreferrer" color="#e5bf1c">
          LUŽÁNKY - středisko volného času
        </a>
        . Její vznik podpořilo Statutární město Brno a je propojena s projektem
        města Brna <a href="https://damenavas.brno.cz/" color="#e5bf1c">Dáme na vás</a> a tím i
        naším projektem{" "}
        <a href="https://projekty.luzanky.cz/" color="#e5bf1c">LUŽÁNKY PRO BRNO</a>
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small" color="primary" href="/home">
        Seznam cest
      </Button>
      <Button size="small" color="primary" href="/home" />
    </CardActions>
  </Card>
);

const WhyCard = () => (
  <Card>
    <CardContent>
      <h2>Lužánecká aplikace</h2>
      <Typography paragraph>
        LApka - &quot;LUŽÁNECKÁ APLIKACE&quot; vznikla jako dárek pro obyvatele a
        návštěvníky města Brna u příležitosti 70. výročí založení organizace{" "}
        <a href="https://www.luzanky.cz/70-sezona" target="_blank" rel="noopener noreferrer">
          LUŽÁNKY - středisko volného času
        </a>
        . Tato aplikace nyní nabízí <a href="/home">7 cest/vycházek</a> po Brně
        a jeho okolí. Každá cesta - trasa má 10 stanovišť, které musí účastník
        projít. Je to tedy 70 míst, které odpovídají 70 letům Lužánek. LApka má
        však v plánu další rozvoj a budou vznikat další vycházkové cesty.
      </Typography>
    </CardContent>
  </Card>
);

const TechnicalCard = () => (
  <Card>
    <CardContent>
      <h2>Jak aplikace funguje?</h2>
      <Typography paragraph>
        LApka je moderní webovou aplikací, která však obsahuje možnost instalace
        přímo na domovskou obrazovku mobilního zařízení. Ihned po prvním
        spuštění sám webový prohlížeč nabídne vložení aplikace na domovskou
        stránku. Podporovaný je operační systém Android, iOS i Windows Phone. Je
        třeba pouze povolit přístup k zjišťování polohy pomocí GPS a aplikace
        bude fungovat.
      </Typography>
      <Typography paragraph>
        Pokud narazíte na chybu nebo budete mít nápad na zlepšení, prosím
        kontaktujte nás. Aplikace je nově v provozu a stále pracujeme na jejím
        zlepšování.
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small" color="primary" href="/help">
        Nápověda
      </Button>
      <Button
        size="small"
        color="primary"
        href="mailto:lapka@luzanky.cz"
        target="_blank"
      >
        E-mail: lapka@luzanky.cz
      </Button>
    </CardActions>
  </Card>
);

const AppOfferCard = () => (
  <Card>
    <CardContent>
      <h2>Co LApka nabízí?</h2>
      <Typography paragraph>
        <ol>
          <li>
            Můžete získat tip na zajímavé místa v Brně a okolí a za pomoci LApky
            je navštívit.
          </li>
          <li>
            Můžete získat zajímavé informace o místech - stanovištích a také
            odpovědět na otázky, které se k těmto místům vztahují.
          </li>
          <li>
            Můžete získat tipy na drobné aktivity v místech kam vás LApka
            zavede.
          </li>
          <li>
            V budoucnu LApka nabídne možnost realizovat trasy různých výletních
            cest v rámci kroužků, akcí pro veřejnost, táborů, nejen v Brně, ale
            v celé ČR.
          </li>
        </ol>
      </Typography>
      <Typography paragraph>
        Budeme rádi, když naši LApku vyzkoušíte a pošlete nám zpětnou vazbu a
        podněty co vylepšit. Věříme, že si LApku zanecháte v oblibě :-)
      </Typography>
    </CardContent>
    <CardActions>
      <Button
        size="small"
        color="primary"
        href="mailto:lapka@luzanky.cz"
        target="_blank"
      >
        Kontakt: lapka@luzanky.cz
      </Button>
    </CardActions>
  </Card>
);

export default observer(function About() {
  React.useEffect(() => {
    uiStore.appBar = {
      page: "about"
    };

    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{
      padding: '10px',
      '& > div': {
        marginBottom: '10px',
      },
    }}>
      <ShortAboutCard />
      <WhyCard />
      <TechnicalCard />
      <AppOfferCard />
    </Box >
  );
});
