import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import uiStore from "../../stores/ui";
import GeoListener from "../../components/GeoListener";
import Popup from "../../components/Popup";
import SubMenu from "../../components/SubMenu";
import PasswordPopup from "../../popups/PasswordPopup";
import { fetchTripDetails } from "../../backend";
import appStore from "../../stores/app";
import { Box, CircularProgress } from "@mui/material";
import { observer } from "mobx-react-lite";

export default observer(function Trip() {
  const navigate = useNavigate()
  const { id } = useParams();
  const tripId = parseInt(id || '', 10);

  React.useEffect(() => {
    if (!id) return;

    fetchTripDetails(tripId);

    uiStore.appBar = { page: "trip", id };
    window.scrollTo(0, uiStore.scroll.trip);
    return () => {
      uiStore.scroll.trip = window.scrollY;
    }
  }, [id, tripId]);

  if (!id || uiStore.appLoading || !appStore.trips.collection[id]) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CircularProgress size={100} />
      </Box>
    );
  }

  const isLocked =
    appStore.trips.collection[id].password &&
    !appStore.trips.unlocked[id];

  if (isLocked) {
    return (
      <Popup
        open
        content={PasswordPopup}
        data={{ onClose: () => navigate(-1), tripId }}
      />
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 1 }}>
      <GeoListener tripId={tripId} />
      <SubMenu items={{ description: "Popis", map: "Mapa", spots: "Stanoviště" }} />
      <Outlet />
    </Box>
  );
})
