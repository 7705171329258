import * as React from "react";
import { Box, GlobalStyles } from "@mui/material";

type Props<T extends { onClose: () => void }> = {
  open: boolean,
  content: React.FunctionComponent<T>;
  data: T;
};

export default function Popup<T extends { onClose: () => void }>({
  content: Content, open, data
}: Props<T>) {
  if (!open) return null;

  return (
    <Box sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#000000ee',
      zIndex: 10000,
    }}>
      <GlobalStyles
        styles={{
          body: {
            overflow: 'hidden',
          },
        }}
      />
      <Box sx={{
        width: '80%',
        maxWidth: '345px',
        position: 'relative',
      }}>
        <Content {...data} />
      </Box>
    </Box>
  );
}
