import * as React from "react";
import { Link } from "react-router-dom";
import { Tooltip, Card, CardMedia, IconButton, CardActions, CardActionArea } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import DirectionIcon from "@mui/icons-material/Directions";
import appStore from "../stores/app";
import uiStore from "../stores/ui";
import { getDistance } from "../utils";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import WalkIcon from "@mui/icons-material/DirectionsWalk";
import { CachedImage } from "../components/CachedImage";
import { observer } from "mobx-react-lite";

export const SpotCard = observer(function SpotCard({ id }: { id: number }) {
  const { title, gps, photo, serial_number, id_trip } = appStore.trips.spot[id];
  const isVisited = appStore.isSpotVisited(id);
  const distanceFromUser = Math.round(getDistance(gps, uiStore.currentUserPosition));

  return (
    <Card>
      <CardActionArea component={Link} to={`/spot/${id}`}>
        <CachedImage image={photo.photo_url} cacheName={`trip-${id_trip}`}>
          {image => (
            <CardMedia
              style={{ height: 140 }}
              image={image}
              title={photo.photo_name}
            />
          )}
        </CachedImage>
        <CardHeader title={`${serial_number}. ${title}`} />
      </CardActionArea>

      <CardActions style={{ display: 'flex' }}>
        <IconButton
          style={{ paddingTop: 0 }}
          aria-label="Navigovat"
          size="small"
          color={isVisited ? "default" : "primary"}
          onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&destination=${gps.lat},${gps.lng}&dir_action=navigate&travelmode=walking`)}
        >
          <DirectionIcon />
          &nbsp;{" "}
          <span style={{ fontSize: 14 }}>
            Online navigace
            <br />
            Google
          </span>
        </IconButton>

        {isVisited ? (
          <IconButton
            style={{ paddingTop: 0, marginLeft: "auto" }}
            aria-label="Místo bylo navštíveno"
            size="large">
            <span style={{ fontSize: 14 }}>navštíveno</span>&nbsp; <DoneIcon />
          </IconButton>
        ) : distanceFromUser > 0 && (
          <Tooltip
            placement="top"
            title={`Vzdálenost přímou čarou: ${distanceFromUser}m`}
            enterTouchDelay={1}
          >
            <IconButton
              style={{ paddingTop: 0, marginLeft: "auto" }}
              aria-label={`Vzdálenost přímou čarou: ${distanceFromUser}m`}
              size="large">
              <WalkIcon />
              <span style={{ fontSize: 14 }}>{distanceFromUser}m</span>
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
});
