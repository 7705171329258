// formReducer.ts

export type ActivityAttributes = {
    activityType: string;
    answerType: string;
    description: string;
    question: string | number;
    correctAnswer: string;
    answerA: string;
    answerAUrl: string;
    answerB: string;
    answerBUrl: string;
    answerC: string;
    answerCUrl: string;
    answerD: string;
    answerDUrl: string;
  }
  
export type SpotAttributes = {
    id: number;
    title: string;   
    serialNumber: string;
    photoUrl: string;
    description: string;
    gps: {
        lng: string;
        lat: string;
    }
    activitiesAttr: ActivityAttributes[];
  }
  
export type TripAttributes = {
    id: number;
    title: string;
    author: string;
    shortDesc: string;
    motivation: string;
    description: string;
    length: string;
    duration: string;
    secretPassword: string;
    profileUrl: string;
    publicTrip: boolean;
    spotsShown: boolean;
    bicycle: boolean;
    babyCarriage: boolean;
  }
  
export type State = {
    tripAttr: TripAttributes;
    spotsAttr: SpotAttributes[];
    spotAttr: SpotAttributes;
    activityAttr: ActivityAttributes;
  }

export const initialState: State = {
    tripAttr: {
        id: -1,
        title: '',
        author: '',
        shortDesc: '',
        motivation: '',
        description: '',
        length: '',
        duration: '',
        secretPassword: '',
        profileUrl: '',
        publicTrip: false,
        spotsShown: false,
        bicycle: false,
        babyCarriage: false,
    },
    spotsAttr: [],
    spotAttr: {
        id: -1,
        title: '',   
        serialNumber: '',
        photoUrl: '',
        description: '',
        gps: {
            lng: '',
            lat: '',
        },
        activitiesAttr: [],
    },
    activityAttr: {
        activityType: '1',
        answerType: '1',
        description: '',
        question: '',
        correctAnswer: '',
        answerA: '',
        answerAUrl: '',
        answerB: '',
        answerBUrl: '',
        answerC: '',
        answerCUrl: '',
        answerD: '',
        answerDUrl: '',
    }
}
  
export type Action = {
    type: string;
    payload: any;
  }
  
  const formReducer = (state: State, action: Action) => {
    switch (action.type) {
      case 'SET_TRIP_ATTR':
        return {
            ...state,
            tripAttr: action.payload
        };
      case 'UPDATE_TRIP_ATTR':
        return {
          ...state,
          tripAttr: {
            ...state.tripAttr,
            ...action.payload
          }
        };
      case 'SET_SPOT_ATTR':
        return {
                ...state,
                spotAttr: action.payload
            };
      case 'UPDATE_SPOT_ATTR':
        return {
            ...state,
            spotAttr: {
                ...state.spotAttr,
                ...action.payload
            }
        };
      case 'ADD_SPOT':
        const existingSpotIndex = state.spotsAttr.findIndex(
          (spot) => spot.id == action.payload.id
        );

        if (existingSpotIndex > -1) {
          // Replace the spot with the same title
          const newSpots = [...state.spotsAttr];
          newSpots[existingSpotIndex] = action.payload;
          return {
            ...state,
            spotsAttr: newSpots
          };
        } else {
          // Add new spot
          return {
            ...state,
            spotsAttr: [
              ...state.spotsAttr,
              action.payload
            ]
          };
        }
      case 'SET_SPOT_AND_ADD':
        const existingSpotIndexx = state.spotsAttr.findIndex(
          (spot) => spot.id == action.payload.id
        );
  
        let newSpots;
        if (existingSpotIndexx > -1) {
          // Replace the spot with the same title
          newSpots = [...state.spotsAttr];
          newSpots[existingSpotIndexx] = action.payload;
        } else {
          // Add new spot
          newSpots = [...state.spotsAttr, action.payload];
        }
  
        return {
          ...state,
          spotAttr: action.payload,
          spotsAttr: newSpots,
        };
      case 'DELETE_SPOT':
        return {
          ...state,
          spotsAttr: state.spotsAttr.filter(
            (spot) => spot.id != action.payload
          ),
        };
      case 'CLEAR_SPOTS':
        return {
          ...state,
          spotsAttr: []
        };
      case 'SET_ACTIVITY_ATTR':
        return {
                ...state,
                activityAttr: action.payload
            };
      case 'UPDATE_ACTIVITY_ATTR':
        return {
            ...state,
            activityAttr: {
                ...state.activityAttr,
                ...action.payload
            }
        };
      case 'DELETE_ACTIVITY':
      return {
        ...state,
        spotAttr: {
          ...state.spotAttr,
          activitiesAttr: state.spotAttr.activitiesAttr.filter(
            (activity) => activity.activityType != action.payload
          )
        }
      };
      case 'ADD_ACTIVITY_TO_SPOT':   
      if (state.spotAttr.activitiesAttr.length != 0) {
        console.log(state.spotAttr.activitiesAttr[0].activityType)
      }
      console.log(action.payload.activityType)     
        const existingActivityIndex = state.spotAttr.activitiesAttr.findIndex(
          (activity) => activity.activityType === action.payload.activityType
        );

        if (existingActivityIndex > -1) {
          // Replace the activity with the same activityType
          const newActivities = [...state.spotAttr.activitiesAttr];
          newActivities[existingActivityIndex] = action.payload;
          return {
            ...state,
            spotAttr: {
              ...state.spotAttr,
              activitiesAttr: newActivities
            }
          };
        } else {
          // Add new activity
          return {
            ...state,
            spotAttr: {
              ...state.spotAttr,
              activitiesAttr: [...state.spotAttr.activitiesAttr, action.payload],
            },
          };
        }
      // add more actions as needed
      default:
        throw new Error();
    }
  };
  
  export default formReducer;
  