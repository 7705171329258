import React, { useState } from "react";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css'; 
import { FormContext } from "./FormContext";
import { useContext } from "react";

function HtmlEditor({name, type}: {name: string, type: string}){

    const { quill, quillRef } = useQuill();

    const formContext = useContext(FormContext);
    if (!formContext) {
      throw new Error('You tried to use FormContext outside of its provider.');
    }
    const { formState, dispatch } = formContext;

    React.useEffect(() => {
        if (quill) {          

          const defaultValue = type.includes("TRIP") ? formState.tripAttr.description : 
                (type.includes("SPOT") ? formState.spotAttr.description : formState.activityAttr.description);

          quill.clipboard.dangerouslyPasteHTML(defaultValue);
          quill.on('text-change', () => {
            console.log(quillRef.current.firstChild.innerHTML);
            dispatch({
                type: type,
                payload: { 
                  [name]: quillRef.current.firstChild.innerHTML
                },
            })
          });
        }        
      }, [quill]);

    return (
        <div style={{ height: '600px' }}>
            <div ref={quillRef} />
        </div>
    );
}
export default HtmlEditor;