import * as React from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { Card, Chip, CardContent, Typography } from "@mui/material";
import appStore from "../stores/app";
import uiStore from "../stores/ui";
import { CachedHtmlView } from "../components/CachedHtmlView";
import { Quiz } from "../components/Quiz";
import { observer } from "mobx-react-lite";

export default observer(function Activity() {
  const { id, difficulty } = useParams();
  const spotId = parseInt(id || '', 10);

  React.useEffect(() => {
    uiStore.appBar = { page: "activity", id: difficulty };
    window.scrollTo(0, 0);
  }, [difficulty]);

  if (uiStore.appLoading) {
    return <div>čekám na připojení k internetu...</div>;
  }

  if (!spotId || !appStore.trips.spot[spotId]) {
    return <Navigate to="/home/list" />;
  }

  const isVisited = appStore.isSpotVisited(spotId);

  const isSolved = appStore.isSpotSolved(spotId, parseInt(difficulty || '', 10));

  if (!isVisited) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Chip
          label="Toto místo nebylo navštíveno" variant="outlined"
          style={{ marginTop: '15px', marginBottom: '20px' }}
        />
      </div>
    );
  }

  const { id_trip, title } = appStore.trips.spot[spotId];
  const selectedActivity = appStore.getActivity(spotId, parseInt(difficulty || '', 10));

  if (!selectedActivity) {
    return <Navigate to={`/trip/${id_trip}/`} />;
  }

  return (
    <div style={{ padding: '10px', textAlign: 'center' }}>
      <Card style={{ textAlign: 'left' }}>
        <CardContent>
          <Typography gutterBottom variant="h2" component="h2">
            {title}
          </Typography>
          <CachedHtmlView cacheName={`trip-${id_trip}`}>
            {selectedActivity.description}
          </CachedHtmlView>
        </CardContent>
      </Card>
      <div>
        <Quiz spotId={spotId} activity={selectedActivity} difficulty={parseInt(difficulty || '', 10)} readOnly={isSolved} />
        {isSolved && (
          <Chip
            label="HOTOVO!"
            variant="outlined"
            color="primary"
            style={{ marginTop: '15px', marginBottom: '20px' }}
            component={Link}
            to={`/spot/${id}`}
          />
        )}
      </div>
    </div>
  );
})
