import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Title from './Title';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import appStore from '../../stores/app';
import { Toolbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { deleteTrip, getTrips } from '../../backend';
import { fetchTripDetails } from '../../backend';

export default function Trips() {

  const navigate = useNavigate();

  const deleteTripConfirm = async (tripId: number) => {
    const canDelete = window.confirm("Jste si jisti, že chcete smazat trasu? Změny nelze vrátit zpět.");
    if (canDelete) {
      await deleteTrip(tripId);
      window.alert("Trasa úspěšne zmazána.")
      await getTrips();
    }
  }

  const editTrip = (tripId: number) => {
    fetchTripDetails(tripId);
    navigate(`/admin/editTrip/${tripId}`);
  }

  return (
    <React.Fragment>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Title>Seznam cest</Title>
        <IconButton
          style={{ backgroundColor: "#006bcc" }} 
          color="inherit"
          aria-label="Add"   
          size='medium' 
          onClick={() => navigate(`/admin/editTrip/new`)}  
          >
          <AddIcon sx={{color: 'white'}} fontSize='medium'/>
        </IconButton>
      </Toolbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Datum změny</TableCell>
            <TableCell>Název</TableCell>
            <TableCell>Autor</TableCell>
            <TableCell align="right">
              Upravit
            </TableCell>
            <TableCell align="right">
              Smazat
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appStore.trips.list?.map((tripId) => (
            <TableRow key={tripId}>
              <TableCell>{tripId}</TableCell>
              <TableCell>{appStore.trips.collection[tripId].timestamp_update}</TableCell>
              <TableCell>{appStore.trips.collection[tripId].title}</TableCell>
              <TableCell>{appStore.trips.collection[tripId].author}</TableCell>
              <TableCell align='right'>
                <IconButton
                  onClick={() => editTrip(tripId)}
                  color="inherit"
                  aria-label="Edit"
                  size="large">
                  <ModeEditIcon sx={{ color: 'blue'}}/>
                </IconButton>
              </TableCell>
              <TableCell align='right'>
                <IconButton
                  onClick={() => deleteTripConfirm(tripId)}
                  color="inherit"
                  aria-label="Delete"
                  size="large">
                  <DeleteIcon sx={{ color: 'red'}}/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
