import React from "react";
import { Outlet } from "react-router-dom";
import uiStore from "../../stores/ui";
import { getTrips } from "../../backend";
import SubMenu from "../../components/SubMenu";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";

export default observer(function Home() {
  React.useEffect(() => {

    uiStore.appBar = {
      page: "home"
    };

    window.scrollTo(0, uiStore.scroll.home);
    return () => {
      uiStore.scroll.home = window.scrollY;
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 1 }}>
      <SubMenu items={{ list: "Cesty", map: "Mapa" }} />
      <Outlet />
    </Box>
  );
});
