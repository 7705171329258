import * as React from "react";
import { optimiseImageUrl } from "../utils";

type Props = {
  children: (src: string) => React.ReactElement;
  image: string;
  cacheName: string;
}

export function CachedImage({ children, image, cacheName }: Props) {
  // use the actual url as fallback in case we didn't cache it
  const [src, setUrl] = React.useState(optimiseImageUrl(image));

  React.useEffect(() => {
    (async () => {
      try {
        const exists = await caches.has(cacheName);
        if (!exists) return;

        const cache = await caches.open(cacheName);
        const imageResponse = await cache.match(src);
        if (!imageResponse) return;

        const blob = await imageResponse.blob();
        setUrl(URL.createObjectURL(blob));
      } catch (e) {
        console.warn("CachedLayer issue", e);
      }
    })()
  }, [cacheName, src]);

  return children(src);
}
