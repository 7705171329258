import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import appStore from "../../stores/app";
import Map from "../../components/Map";
import { Box } from "@mui/material";
import { TripCard } from "../../components/TripCard";
import { observer } from "mobx-react-lite";

export default observer(function HomeMapPage() {
  if (!appStore.trips.list) {
    return (
      <Box sx={{ padding: '10px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={100} />
        </Box>
      </Box>
    );
  }

  return (
    <Map
      popup={({ id }) => <TripCard tripId={id} />}
      isTripView
      spots={appStore.trips.list.map((tripId, i) => {
        const { id, gps } = appStore.trips.collection[tripId];
        return { id, gps, serial_number: i + 1 };
      })}
      isSpotVisited={appStore.getTripScoreAvailableForVote}
    />
  );
});
