import React from "react";
import { Card, CardHeader, CardContent, CardActions, CardMedia, Button, Typography } from "@mui/material";
import uiStore from "../stores/ui";
import { OFFICES } from "../constants";
import { optimiseImageUrl } from "../utils";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";

const InfoCard = () => (
  <Card>
    <CardContent>
      <img alt="" style={{ width: '100%' }} src="/images/luzanky_logo_big.png" />
      <Typography component="p" sx={{pt: 1 }}>
        NAŠE POSLÁNÍ: Posláním Lužánek - střediska volného času je inspirovat, motivovat a poskytovat prostor pro volný
        čas a osobní rozvoj každého člověka. <br />
        <br />
        Jsme brněnská organizace pořádající kroužky, tábory a další akce pro děti,
        mladé lidi i dospělé. A to už doslova pro několik generací, je nám letos totiž 70 let! Nejsme ale jen historická
        budova v parku, který nám dal jméno - najdete nás na jedenácti místech v Brně a okolí. Ať bydlíte v Brně
        kdekoli, určitě od vás nejsme daleko.<br />
        <br />
        <a href="https://www.luzanky.cz/Historie-Luzanek/" target="_blank" rel="noopener noreferrer">Historie Lužánek</a><br />
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small" color="primary" href="https://www.luzanky.cz/" target="_blank" rel="noopener noreferrer">
        www.luzanky.cz
      </Button>
      <Button size="small" color="primary" href="https://luzanky.live.cz/" target="_blank" rel="noopener noreferrer">
        Magazín LIVE!
      </Button>
    </CardActions>
  </Card>
);

export default observer(function Luzanky() {
  React.useEffect(() => {
    uiStore.appBar = {
      page: "luzanky"
    };
  }, []);

  return (
    <Box sx={{
      padding: '10px',
      '& > div': {
        marginBottom: '10px',
      },
    }}>
      <InfoCard />
      <h2>Naše pracoviště</h2>
      <Typography component="p">
        Následuje výčet všech našich pracovišť. Zároveň jsou tyto pracoviště vyznačeny na mapě malým logem naší firmy :)
      </Typography>
      <br />

      {OFFICES.map(({ name, web, description, photo_url }) => (
        <Card key={name}>
          {photo_url && (
            <CardMedia
              style={{ height: '150px' }}
              image={optimiseImageUrl(photo_url)}
              title={name}
            />
          )}

          <CardHeader title={name} subheader={description} />

          <CardActions>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => window.open(web)}
            >
              Přejít na web pracoviště
            </Button>
          </CardActions>
        </Card>
      ))}
    </Box>
  );
});
