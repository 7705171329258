import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Title from './Title';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import appStore from '../../stores/app';
import { Toolbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { DIFFICULTY_TEXT } from "../../types";
import { useContext } from 'react';
import { FormContext } from '../../FormContext';
import { observer } from 'mobx-react-lite';
import { deleteActivity, getTrips } from '../../backend';

export default observer(function Activities({spotId}: {spotId: number}) {

  const navigate = useNavigate();

  const formContext = useContext(FormContext);
  if (!formContext) {
      throw new Error('You tried to use FormContext outside of its provider.');
  }
  const { formState, dispatch } = formContext;

  const editActivity = (spotId: number, type: number) => {
    const activity = formState.spotAttr.activitiesAttr.find(a => parseInt(a.activityType) == type);
    if (activity) {
      dispatch({type: 'SET_ACTIVITY_ATTR', payload: activity });
    };
    navigate(`/admin/editActivity/${spotId}/${type}`);
  }

  const numberOfActivities = () => {
    let number = 0;
    Array.from({ length: 3 }, (_, index) => index + 1).forEach((index) => {
    if (formState.spotAttr.activitiesAttr.find(a => parseInt(a.activityType) == index) || appStore.getActivity(spotId, index)) {
      ++number;
    }});
    return number;
  }

  const deleteActivityConfirm = async (difficulty: number) => {
    const canDelete = window.confirm("Jste si jisti, že chcete smazat aktivitu? Změny nelze vrátit zpět.");
    if (canDelete) {
      if (appStore.getActivity(spotId, difficulty)) {
        await deleteActivity(spotId, difficulty);
        dispatch({ type: 'DELETE_ACTIVITY', payload: difficulty });
        await getTrips();
      } else if (formState.spotAttr.activitiesAttr.some(a => parseInt(a.activityType) == difficulty)) {
        dispatch({ type: 'DELETE_ACTIVITY', payload: difficulty });
        window.alert("Aktivita úspěšne zmazána.");
      }
    } 
  }

  return (
    <React.Fragment>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Title>Seznam aktivit</Title>
        { numberOfActivities() < 3 &&
        <IconButton
          style={{ backgroundColor: "#006bcc" }} 
          color="inherit"
          aria-label="Add"   
          size='medium'   
          onClick={() => navigate(`/admin/editActivity/${spotId}/new`)}
          >
          <AddIcon sx={{color: 'white'}} fontSize='medium'/>
        </IconButton> }
      </Toolbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Typ aktivity</TableCell>
            <TableCell>Název typu aktivity</TableCell>
            <TableCell align="right">
              Upravit
            </TableCell>
            <TableCell align="right">
              Smazat
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {Array.from({ length: 10 }, (_, index) => index + 1).map((index) => {
              const activity = formState.spotAttr.activitiesAttr.find(a => parseInt(a.activityType) == index);
              if (appStore.getActivity(spotId, index) || activity) {
                return (
                  <TableRow key={index}>
                      <TableCell>{index}</TableCell>
                      <TableCell>{DIFFICULTY_TEXT.get(parseInt(index.toString() || '', 10))}</TableCell>
                      <TableCell align='right'>
                          <IconButton
                          color="inherit"
                          aria-label="Edit"
                          size="large"
                          onClick={() => editActivity(spotId, index)}
                          >                          
                          <ModeEditIcon sx={{ color: 'blue'}}/>
                          </IconButton>
                      </TableCell>
                      <TableCell align='right'>
                          <IconButton
                          color="inherit"
                          aria-label="Delete"
                          size="large"
                          onClick={() => deleteActivityConfirm(index)}
                          >                            
                          <DeleteIcon sx={{ color: 'red'}}/>
                          </IconButton>
                      </TableCell>
                  </TableRow>
                ); 
              }                                                
            })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
})
