import React from "react";
import { Link } from "react-router-dom";
import { createUser, getTrips } from "../backend";
import { Navigate } from "react-router-dom";
import uiStore from "../stores/ui";
import { Box, Button, Typography, Card, CardContent, CardActions, CardHeader } from "@mui/material";

const Welcome = () => (
  <Card>
    <CardHeader title="LApka - uvítací obrazovka" />
    <CardContent>
      <img alt="" style={{ maxWidth: '50%' }} src="/images/lapka_logo.png" />
      <Typography component="p">
        Vítejte! Lapka je mobilní hravá aplikace do mobilu, která vás provede po
        Brně místy, která možná neznáte, nebo jste o nich jen něco slyšeli a
        zajímá vás to. LApka vám nabízí procházku a objevování nových míst. Pro
        zvídavé nabízí i zajímavé informace, otázky, řešení úkolů, či šifer. Ale
        je také pro malé předškoláky, kteří jdou na výlet se staršími a třeba si
        po cestě chtějí něco zahrát. LApka je pro každého, malého, velkého, s
        kočárkem, na koloběžce či pěšky. Pojďte ji zkusit ;-)
      </Typography>
    </CardContent>
    <CardActions>
      <Button component={Link} size="small" color="primary" to="/home/list">
        Seznam cest
      </Button>
      <Button component={Link} size="small" color="primary" to="/help">
        Nápověda
      </Button>
    </CardActions>
  </Card>
);

const AddToTheHomeScreen = () => {
  if (window.matchMedia("(display-mode: standalone)").matches) {
    return null;
  }

  return (
    <>
      {navigator.userAgent.match(/iPhone|iPad|iPod/i) && (
        <div style={{ textAlign: 'center' }}>
          <Card>
            <CardContent>
              <Typography component="p">
                Klikni na <img alt="sdílet" style={{ width: '15px' }} src="/images/share-ios.png" /> a zvol
                &quot;Přidat na plochu&quot;
              </Typography>
            </CardContent>
          </Card>
        </div>
      )}

      <Card>
        <CardContent>
          <h3>Jak nainstalovat LApku do telefonu?</h3>
          <Typography component="p">
            Stačí si aplikaci přidat na plochu a můžete ji používat jako ostatní
            aplikace, které máte nainstalované v telefonu.
          </Typography>

          <Typography component="p">
            Webový prohlížeč sám nabídne vložení aplikace na domovskou stránku.
            Případně využijte menu webového prohlížeče a vyhledejte možnost
            &quot;Přidat na domovskou obrazovku&quot; Je třeba pouze povolit přístup k
            zjišťování polohy pomocí GPS a aplikace bude fungovat.
          </Typography>

          <Typography component="p">
            Pokud webový prohlížeč nemá přístup k poloze GPS, je pro používání
            LApky třeba toto oprávnění přidělit přímo webovému přohlížeči. Na
            systému Android se jedná většinou o Google Chrome, systémy iOS potom
            mají prohlížeč Safari.
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default function Intro() {
  React.useEffect(() => {
    createUser();
    window.scrollTo(0, 0);
  }, []);

  if (uiStore.appLoading) {
    return <Navigate to="/" />
  }

  return (
    <Box sx={{
      padding: '10px',
      '& > div': {
        marginBottom: '10px',
      },
    }}>
      <Welcome />
      <AddToTheHomeScreen />
    </Box>
  );
}
