import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useState } from 'react';
import appStore from '../../stores/app';
import { ANSWER_TYPE } from '../../types';
import { useContext } from 'react';
import { FormContext } from '../../FormContext';
import { useNavigate } from 'react-router';
import { initialState } from '../../stores/admin';
import HtmlEditor from '../../htmlTextEditor';


export default function ActivityForm({spotId, difficulty, newActivity} :
     {spotId: number, difficulty: number, newActivity: boolean}) {

    const activity = appStore.getActivity(spotId, difficulty);

    const formContext = useContext(FormContext);

    if (!formContext) {
        throw new Error('You tried to use FormContext outside of its provider.');
    }

    const { formState, dispatch } = formContext;

    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch({
            type: 'SET_ACTIVITY_ATTR',
            payload: newActivity ? formState.activityAttr : {
                activityType: formState.activityAttr.activityType != "1" ?
                    formState.activityAttr.activityType : (activity ? activity.id_activity_type.toString() : "1"),
                answerType: formState.activityAttr.answerType != "1" ?
                    formState.activityAttr.answerType : (activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? "1" : "2"),
                description: formState.activityAttr.description ? formState.activityAttr.description : (activity ? activity.description : ''),
                question: formState.activityAttr.question ? formState.activityAttr.question : (activity ? (activity.test?.question || '') : ''),
                correctAnswer: formState.activityAttr.correctAnswer ? formState.activityAttr.correctAnswer :
                    (activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.answer != null ?
                    (['A','B','C','D'])[activity.test?.answer] : '') : (activity && activity.test?.answer ? activity.test?.answer.join("|") : '')),
                answerA: formState.activityAttr.answerA ? formState.activityAttr.answerA :
                    (activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[0]?.text || '') : ''),
                answerAUrl: formState.activityAttr.answerAUrl ? formState.activityAttr.answerAUrl :
                    (activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[0]?.url || '') : ''),
                answerB: formState.activityAttr.answerB ? formState.activityAttr.answerB :
                    (activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[1]?.text || '') : ''),
                answerBUrl: formState.activityAttr.answerBUrl ? formState.activityAttr.answerBUrl :
                    (activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[1]?.url || '') : ''),
                answerC: formState.activityAttr.answerC ? formState.activityAttr.answerC :
                    (activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[2]?.text || '') : ''),
                answerCUrl: formState.activityAttr.answerCUrl ? formState.activityAttr.answerCUrl :
                    (activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[2]?.url || '') : ''),
                answerD: formState.activityAttr.answerD ? formState.activityAttr.answerD :
                    (activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[3]?.text || '') : ''),
                answerDUrl: formState.activityAttr.answerDUrl ? formState.activityAttr.answerDUrl :
                    (activity && activity.id_answer_type === ANSWER_TYPE.ABCD ? (activity.test?.options[3]?.url || '') : ''),
            }
        });
    }, [activity, newActivity]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
            dispatch({
              type: "UPDATE_ACTIVITY_ATTR",
              payload: { [name]: value },
            }
        );
    }

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === 'answerType') {
            ['answerA', 'answerB', 'answerC', 'answerD', 'answerAUrl',
             'answerBUrl', 'answerCUrl', 'answerDUrl'].map(answer => 
                dispatch({type: "UPDATE_ACTIVITY_ATTR", payload: { [answer]: '' }}));
        }
        dispatch({
            type: "UPDATE_ACTIVITY_ATTR",
            payload: { [name]: value },
        });
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (event.currentTarget.reportValidity()) {
            const answers = [formState.activityAttr.answerA, formState.activityAttr.answerB,
                formState.activityAttr.answerC, formState.activityAttr.answerD,
                formState.activityAttr.answerAUrl, formState.activityAttr.answerBUrl,
                formState.activityAttr.answerCUrl, formState.activityAttr.answerDUrl]

            if (formState.activityAttr.question && !formState.activityAttr.correctAnswer) {
                window.alert("Nemůžete vytvořit aktivitu s otázkou bez správné odpovědi.")
            }            
            else if (formState.activityAttr.question && formState.activityAttr.answerType == "1" && answers.filter(attr => attr !== '').length < 2) {
                window.alert("Nemůžete vytvořit aktivitu s otázkou a odpovědí typu ABCD bez alespoň dvou možností.")
            }
            else {
                dispatch({
                    type: 'ADD_ACTIVITY_TO_SPOT',
                    payload: formState.activityAttr                
                    });
                dispatch({
                    type: 'SET_ACTIVITY_ATTR',
                    payload: initialState.activityAttr
                });
                navigate(-1);
            }           
        } else {            
            console.log('Some required fields are empty');
        }
    };

    return (
        <React.Fragment>     
            <form onSubmit={handleSubmit}>   
                <Grid container spacing={6}>                
                <Grid item xs={12}>
                  <label>
                    {"Typ aktivity: "}
                    <select name='activityType' value={formState.activityAttr.activityType} onChange={handleSelectChange}>
                        <option value="1">1 - Znalec</option>
                        <option value="2">2 - Hádankář</option>
                        <option value="3">3 - Mrňous</option>
                    </select>
                  </label>
                </Grid>                               
                <Grid item xs={12} >
                    <label>Popis aktivity</label>
                    <HtmlEditor 
                        name='description'
                        type='UPDATE_ACTIVITY_ATTR'
                    />
                </Grid>
                <Grid item xs={12} sx={{mt: 4}} >
                    <TextField
                    fullWidth
                    name='question'
                    value={formState.activityAttr.question}
                    label="Otázka"
                    multiline
                    rows={2}
                    onChange={handleInputChange}
                    />
                </Grid>
                {formState.activityAttr.question != '' &&
                <Grid item xs={12} sm={6}>
                <label>
                    {"Typ odpovědi: "}
                    <select name='answerType' value={formState.activityAttr.answerType} onChange={handleSelectChange}>
                        <option value="1">1 - ABCD</option>
                        <option value="2">2 - TEXT</option>
                    </select>
                </label>
                </Grid> }
                {formState.activityAttr.question != '' &&
                <Grid item xs={12}>
                    <TextField
                    label="Správná odpověď - více možností oddělujte - A|B|C|D"
                    fullWidth
                    name='correctAnswer'
                    value={formState.activityAttr.correctAnswer}
                    onChange={handleInputChange}
                    />
                </Grid> }                  
                { formState.activityAttr.question && formState.activityAttr.answerType == "1" &&
                <>
                <Grid item xs={12} sm={6}>
                    <TextField
                    label="Odpověď A"
                    fullWidth
                    name='answerA'
                    value={formState.activityAttr.answerA}
                    onChange={handleInputChange}
                    />
                </Grid>   
                <Grid item xs={12} sm={6}>
                    <TextField
                    label="Odpověď B"
                    fullWidth
                    name='answerB'
                    value={formState.activityAttr.answerB}
                    onChange={handleInputChange} 
                    />
                </Grid>                 
                <Grid item xs={12} sm={6}>
                    <TextField
                    label="Odpověď C"
                    fullWidth
                    name='answerC'
                    value={formState.activityAttr.answerC}
                    onChange={handleInputChange}
                    />
                </Grid> 
                <Grid item xs={12} sm={6}>
                    <TextField
                    label="Odpověď D"
                    fullWidth
                    name='answerD'
                    value={formState.activityAttr.answerD}
                    onChange={handleInputChange}
                    />
                </Grid> 
                <Grid item xs={12} sm={6}>
                    <TextField
                    label="Image url odpovědi A"
                    fullWidth
                    name='answerAUrl'
                    value={formState.activityAttr.answerAUrl}
                    onChange={handleInputChange}
                    />
                </Grid>    
                <Grid item xs={12} sm={6}>
                    <TextField
                    label="Image url odpovědi B"
                    fullWidth
                    name='answerBUrl'
                    value={formState.activityAttr.answerBUrl}
                    onChange={handleInputChange}
                    />
                </Grid>  
                <Grid item xs={12} sm={6}>
                    <TextField
                    label="Image url odpovědi C"
                    fullWidth
                    name='answerCUrl'
                    value={formState.activityAttr.answerCUrl}
                    onChange={handleInputChange} 
                    />
                </Grid>  
                <Grid item xs={12} sm={6}>
                    <TextField
                    label="Image url odpovědi D"
                    fullWidth
                    name='answerDUrl'
                    value={formState.activityAttr.answerDUrl}
                    onChange={handleInputChange}
                    />
                </Grid>
                </> }            
                </Grid>
                <Button 
                    type='submit'   
                    sx={{mt: 2}}                  
                >
                    Uložit
                </Button>
            </form>    
        </React.Fragment>
    );
}