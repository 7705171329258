import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useParams } from "react-router-dom";
import ActivityForm from './ActivityForm';
import './admin-dashboard.css';

export default function ActivityEdit() {

  const { id, difficulty } = useParams();
  const spotId = parseInt(id || '', 10);
  const diff = parseInt(difficulty || '', 10);
  const newActivity = diff ? false : true;

  return (
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            {/* Activity Information */}
            <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <ActivityForm spotId={spotId} difficulty={diff} newActivity={newActivity} />
                </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
  );
}
