import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { copyUserActivities, copyUserVisits, sendEmail, signUp } from '../backend';
import uiStore from "../stores/ui";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const theme = createTheme();

export default function SignUp() {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
    });
  };

  React.useEffect(() => {
    uiStore.appBar = {
      page: "signUp"
    };

    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [emailReg, setEmailReg] = useState('');  
  const [passwordReg, setPasswordReg] = useState('')
  const [passwordRegConfirm, setPasswordRegConfirm] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [saveProgress, setSaveProgress] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
  const handleMouseDownPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
  

  const [showWarning, setShowWarning] = React.useState(false);
  const [warning, setWarning] = React.useState('');

  const register = async () => {  
    if (emailReg == '' || passwordReg == '' || passwordRegConfirm == '') {
      setWarning('Vyplňte prosím všechny údaje.');
      setShowWarning(true);
      return;
    }    
    if (passwordReg != passwordRegConfirm) {
      setWarning('Hesla se musí zhodovat.');
      setShowWarning(true);
      return;
    } 
    const response = await signUp(emailReg, passwordReg);
  
    if (response.result == 0) {
      const header = 'LApka - Dokončení registrace';
      const message = `<p>Dobrý den,</p> 
                      <p>Pro dokončení registrace prosím klikněte na nasledující link:</p>
                      <a href="` + window.location.origin + `/verify/` + response.token + `">Potvrdit e-mail</a>
                      <p style="margin-bottom:0;">
                          S pozdravem,
                      </p>
                      <p style="margin : 0; padding-top:0;">
                          LApka Team
                      </p>`;
      sendEmail(process.env.EMAIL_LOGIN || '', emailReg, message, header);
      if (saveProgress) {        
        copyUserVisits(response.userId);
        copyUserActivities(response.userId);
      }      
      setOpen(true)

    }
    else if (response.result == 1) {
      setWarning('Účet se zadanou přezdívkou nebo emailem je již zaregistrován.');
      setShowWarning(true);
    }
    else {
      setWarning('Při registraci nastala nečekaná chyba. Prosím kontaktujte podporu LApky.');
      setShowWarning(true);
    }    
  };

  return (
    <Box sx={{
        padding: '10px',
        '& > div': {
        marginBottom: '10px',
        },
    }}>
        <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >          
          <img alt="" style={{ width: '90%' }} src="/images/lapka_logo.png" />          
          <Typography component="h1" variant="h5">
            Registrace
          </Typography>
          { showWarning ? <Typography sx={{ mt: 1}} align='center' color='red'>
            {warning}
          </Typography> : null }
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Emailová adresa"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => {setEmailReg(e.target.value)}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                required
                fullWidth
                name="password"
                label="Heslo"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={(e) => {setPasswordReg(e.target.value)}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                required
                fullWidth
                name="password"
                label="Heslo znovu"
                type={showPasswordConfirm ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={(e) => {setPasswordRegConfirm(e.target.value)}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPasswordConfirm}
                      >
                        {showPasswordConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={saveProgress}
                        onChange={(e) => setSaveProgress(e.target.checked)}
                    />}
                    label="Přenést údaje do nového účtu"
                /> 
                <Tooltip
                  title="Pokud zvolíte tuto možnost, veškerý dosavadní pokrok 
                  na tomto zařízení se přenese do nově vytvořeného účtu. Tímto
                  způsobem nebude ztracen žádný postup v rámci absolvovaných tras."
                  enterTouchDelay={1}
                >
                  <IconButton aria-label="Help" size="large">
                    <HelpOutlineIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={register}
            >
              Registrovat
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2" component={RouterLink} to="/signIn">
                  Už máte účet? Přihlašte se
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Dialog open={open} PaperProps={{ sx: { width: "80%" } }}>
          <Box
            sx={{
              marginTop: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CheckCircleOutlineIcon color='success' sx={{ fontSize: 70 }} />
            <Typography variant="h5">
              Úspěšne zaregistrováno.
            </Typography>  
            <Typography variant="subtitle2" align='center'>
              Pro dokončení registrace prosím klikněte na link zaslaný na váš email.
            </Typography> 
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              onClick={() => navigate('/signIn')}
            >
              OK
            </Button>         
          </Box>
        </Dialog>
      </Container>
    </ThemeProvider>
    </Box >
    
  );
}