import { indexBy, prop, map, mergeRight } from "ramda";
import deepmerge from "deepmerge";
import { action, computed, configure, makeAutoObservable, observable, toJS } from "mobx";
import { saveApplicationData } from "../storage";
import { createUser, getTrips, getUserVisits, userVisitSpot } from "../backend";
import { DIFFICULTY_TEXT, Rating } from "../types";
import { File, Trip, Spot, Progress } from "../types";

export const defaultTrips = {
  spot: {},
  file: {},
  ratings: {},
  collection: {},
  progress: {},
  list: null,
  cached: {},
  unlocked: {},
  showSpots: {}
};

export class AppStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  @observable
  public user: number | null = null;

  @observable
  public nickname: string | null = null;

  @observable
  public email: string | null = null;

  @observable
  public token: string | null = null;

  @observable
  public admin: boolean = false;

  @observable
  public trips: {
    collection: { [key: string]: Trip },
    spot: { [key: string]: Spot },
    file: { [key: string]: File[] },
    ratings: { [key: string]: Rating },
    progress: { [key: string]: Progress },
    list: number[] | null,
    cached: { [key: string]: boolean },
    unlocked: { [key: string]: boolean },
    showSpots: { [key: string]: boolean}
  } = { ...defaultTrips };

  @observable
  public activeTrip: number | null = null;

  @observable
  public pendingOperations: {
    spot: { [key: string]: { lat: number, lng: number } };
    activity: { [key: string]: { answer: string, correct: boolean } }
  } = { spot: {}, activity: {} };

  @computed
  public getTripScoreAvailableForVote(tripId: number) {
    // hotfix, until are trips loaded, they have 0 spots thus they are not finished
    if (this.trips.collection[tripId].spots.length === 0) {
      return false;
    }
    return (
      this.trips.collection[tripId].spots
        .map(spotId => this.isSpotVisited(spotId) && !this.pendingOperations.spot[spotId])
        .filter(a => a).length === this.trips.collection[tripId].spots.length
    );
  }

  @action
  public login(userId: number, token: string, nickname: string | null, email: string | null, admin: boolean) {
    this.user = userId;
    this.token = token;
    this.nickname = nickname;
    this.email = email;
    this.admin = admin;
    saveApplicationData();
  }

  @action
  public signOut() {
    this.user = null;
    this.token = null;
    this.nickname = null;
    this.email = null;
    this.activeTrip = null;
    this.admin = false;
    this.trips = {
      cached: {},
      collection: {},
      file: {},
      ratings: {},
      list: null,
      progress: {},
      spot: {},
      unlocked: {},
      showSpots: {},
    };
    this.pendingOperations = {
      activity: {},
      spot: {},
    };
    getTrips();
    saveApplicationData();
  }

  @action
  public updateNickname(nickname: string) {
    this.nickname = nickname;
    saveApplicationData();
  }
  

  @action
  public loadTrips(data: { id: number }[]) {
    this.trips.list = map(prop("id"), data);
    this.trips.collection = deepmerge(
      toJS(this.trips.collection),
      indexBy(prop("id"), data.map(x => ({ ...x, spots: [], ratings: [] }))),
    );

    saveApplicationData();
  }

  @action
  public saveTripDetails(tripId: number, { spot, file, rating }: { spot: Spot[]; file: File[]; rating: Rating[] }) {
    const indexedSpots = indexBy(prop("id"), spot);
    const indexedRatings = indexBy(prop("id"), rating);

    this.trips.spot = mergeRight(this.trips.spot, indexedSpots);
    this.trips.ratings = mergeRight(this.trips.ratings, indexedRatings);
    this.trips.file[tripId] = file;

    const trip = this.trips.collection[tripId];
    if (trip) {
      trip.spots = spot.map(x => x.id);
      trip.ratings = rating.map(x => x.id);
    }
    saveApplicationData();
  }

  @action
  public loadUserVisits(data: any[]) {    
    data.forEach(spot => {this.trips.progress[spot.id_spot] = {
      visited: true,
      activity: {}
    }});
    saveApplicationData();
  }

  @action
  public makeTripActive(tripId: number) {
    this.activeTrip = tripId;
    saveApplicationData();
  }

  @action
  public deactivateTrip() {
    this.activeTrip = null;
    saveApplicationData();
  }

  @action
  public hideTripSpots(tripId: number) {
    this.trips.showSpots[tripId] = false;
    saveApplicationData();
  }

  @action
  public viewTripSpots(tripId: number) {
    this.trips.showSpots[tripId] = true;
    saveApplicationData();
  }

  @action
  public spotVisited(spotId: number) {
    this.trips.progress[spotId] = {
      visited: true,
      activity: {}
    };
    userVisitSpot(spotId.toString());
    saveApplicationData();
  }

  @action
  public unlockTrip(tripId: number) {
    this.trips.unlocked = {
      ...this.trips.unlocked,
      [tripId]: true
    };
    saveApplicationData();
  }

  @action
  public activitySolved(spotId: number, difficulty: number) {
    if (!this.trips.progress[spotId]) {
      this.trips.progress[spotId] = {
        activity: {},
        visited: true
      };
    }

    this.trips.progress[spotId].activity[difficulty] = true;
    saveApplicationData();
  }

  @computed
  public isSpotVisited(spotId: number) {
    return this.trips.progress[spotId]?.visited;
  }

  @computed
  public isSpotSolved(spotId: number, difficulty: number) {
    return !!this.trips.progress[spotId]?.activity?.[difficulty];
  }

  @computed
  public getActivity(spotId: number, difficulty: number) {
    return this.trips.spot[spotId]?.activity?.[difficulty];
  }

  @computed
  public getTripScore(tripId: number) {
    const progress = this.trips.collection[tripId].spots.map(spotId => {
      let score = this.isSpotVisited(spotId) ? 1 : 0;
      [...DIFFICULTY_TEXT.keys()].forEach(d => {
        score += this.isSpotSolved(spotId, d) ? 1 : 0;
      });
      return score;
    });

    return {
      list: progress,
      percentage: Math.ceil((progress.reduce((a, v) => a + v, 0) / (progress.length * 3)) * 100) || 0
    };
  }

  @computed
  public getTripRatingAverage(tripId: number) {
    const ratings = this.trips.collection[tripId].ratings.map(ratingId => this.trips.ratings[ratingId].rating);
    return ratings.length == 0 ? 0 : (Math.round((ratings.reduce( (p, c) => p + c, 0 ) / ratings.length) * 10) / 10).toFixed(1);
  }

  @computed
  public getMaxDuration() {
    let maxDuration = 0;
    this.trips.list?.forEach(tripId => this.trips.collection[tripId].duration > maxDuration ? maxDuration = this.trips.collection[tripId].duration : null)
    return maxDuration;
  }

  @computed
  public getMaxLength() {
    let maxLength = 0;
    this.trips.list?.forEach(tripId => this.trips.collection[tripId].length > maxLength ? maxLength = this.trips.collection[tripId].length : null)
    return maxLength;
  }
}

configure({
  enforceActions: 'never',
});
export const appStore = new AppStore();

export default appStore;
