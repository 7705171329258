import L from "leaflet";
import { xyz } from './utils';
import appStore from "./stores/app";
import { optimiseImageUrl } from "./utils";
import { MAP_SETTINGS } from "./constants";
import uiStore from "./stores/ui";
import { Spot } from "./types";
import { reaction } from "mobx";
import axios from "axios";
import { getTrips } from "./backend";

function getMapFiles(spots: Spot[]): string[] {
  if (!spots.length) return [];

  const tileBounds = L.latLngBounds(spots.map(spot => [spot.gps.lat, spot.gps.lng]));

  const tiles = xyz(
    [
      [tileBounds.getSouthWest().lng, tileBounds.getSouthWest().lat],
      [tileBounds.getNorthEast().lng, tileBounds.getNorthEast().lat]
    ],
    MAP_SETTINGS.minZoom,
    MAP_SETTINGS.maxZoom
  );

  return tiles.map(({ x, y, z }) =>
    MAP_SETTINGS.url
      .replace("{x}", x.toString())
      .replace("{y}", y.toString())
      .replace("{z}", z.toString())
  );
}

export function getTripFiles(tripId: number) {
  const spots = appStore.trips.collection[tripId].spots.map(spotId => appStore.trips.spot[spotId]);

  const files = (appStore.trips.file[tripId] || []).map(({ file: { file_url } }) => (
    file_url.match(/jpe?g|png|gif$/) ? optimiseImageUrl(file_url) : file_url
  ));

  if (!spots) return [];
  return spots
    .map(spot => optimiseImageUrl(spot.photo.photo_url))
    .concat(files)
    .concat(getMapFiles(spots));
}

export async function cacheTrip(tripId: number) {
  uiStore.downloadCachePending = true;
  const cache = await caches.open(`trip-${tripId}`);
  const files = getTripFiles(tripId);

  await Promise.all(files.map(async (file) => {
    try {
      await cache.add(file);
    } catch (e) {
      console.warn(`File ${file} cannot be fetched!`);
    }
  }));

  appStore.trips.cached[tripId] = true;
  uiStore.downloadCachePending = false;
}

export async function removeTripFromCache(tripId: number): Promise<boolean> {
  appStore.trips.cached[tripId] = false;
  return caches.delete(`trip-${tripId}`);
}

export async function isTripCached(tripId: number | string): Promise<boolean> {
  const cacheName = `trip-${tripId}`;

  const exists = await caches.has(cacheName);
  if (!exists) return false;

  const cache = await caches.open(cacheName);
  const cacheKeys = await cache.keys();

  return !!cacheKeys.length;
}

export async function saveApplicationData() {
  localStorage.setItem("state", JSON.stringify(appStore));
}

export async function purgeApplicationData() {
  const canPurge = window.confirm("Jste si jisti, že chcete resetovat aplikaci? Pokud nejste přihlášeni, ztratíte všechny dosažené trasy a aktivity. Změny nelze vrátit zpět.");
  if (canPurge) {
    localStorage.removeItem("state");
    appStore.user = null;
    appStore.token = null;
    appStore.email = null;
    appStore.nickname = null;
    appStore.admin = false;
    appStore.activeTrip = null;
    appStore.trips = {
      cached: {},
      collection: {},
      file: {},
      ratings: {},
      list: null,
      progress: {},
      spot: {},
      unlocked: {},
      showSpots: {},
    };
    appStore.pendingOperations = {
      activity: {},
      spot: {},
    };
  }
}

const Boot = async () => {
  const state = localStorage.getItem("state");
  if (state) {
    const persistedState = JSON.parse(state);
    appStore.user = persistedState.user;    
    appStore.token = persistedState.token;
    appStore.email = persistedState.email;
    appStore.nickname = persistedState.nickname;
    appStore.admin = persistedState.admin;
    appStore.activeTrip = persistedState.activeTrip;
    appStore.trips = persistedState.trips;
    appStore.pendingOperations = persistedState.pendingOperations;
  }
  await getTrips();
  Object.keys(appStore.trips.collection).forEach(async (tripId) => {
    appStore.trips.cached[tripId] = await isTripCached(tripId);
  });
  uiStore.appLoading = false;
};
export default Boot;

reaction(
  () => appStore.user,
  user => {
    console.log(
      `[backend] ${user ? "User is logged in" : "User was logged off"}`
    );
    if (user) {
      axios.defaults.headers["x-user"] = user;
    } else {
      delete axios.defaults.headers["x-user"];
    }
  }
);
