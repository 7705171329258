import * as React from "react";
import { Chip, Typography, Paper, Radio, RadioGroup, TextField, Button, FormControlLabel, FormControl } from "@mui/material";
import appStore from "../stores/app";
import { userLogActivity } from "../backend";
import { Link } from "react-router-dom";
import { CachedImage } from "../components/CachedImage";
import { Activity, ANSWER_TYPE } from "../types";
import { observer } from "mobx-react-lite";

const ABCD = ["0", "1", "2", "3"];

type Props = {
  spotId: number;
  activity: Activity;
  difficulty: number;
  readOnly: boolean;
};

export const Quiz = observer(function Quiz({ spotId, activity, difficulty, readOnly }: Props) {
  const [value, setValue] = React.useState('');
  const [answeredCorrectly, setAnsweredCorrectly] = React.useState<boolean | null>(null);
  const { id_trip } = appStore.trips.spot[spotId];

  React.useEffect(() => {
    if (!activity.test) {
      console.info("No test found so let's assume it was solved properly");
      appStore.activitySolved(spotId, difficulty);
      userLogActivity(spotId.toString(), { answer: "", correct: true });
    }

    // if it's read only, it's already solved, so let's show the correct value
    if (readOnly && activity.test) {
      if (activity.id_answer_type === ANSWER_TYPE.ABCD) {
        setValue(ABCD[activity.test.answer]);
      } else {
        setValue(activity.test.answer[0]);
      }
    }
  }, [activity, difficulty, readOnly, spotId]);

  const checkAnswer = () => {
    if (!value || !activity.test) return;
    let isCorrect = null;

    if (activity.id_answer_type === ANSWER_TYPE.ABCD) {
      isCorrect = parseInt(value, 10) === activity.test.answer;
    } else {
      const currentValue = value.toLowerCase();

      const possibleAnswers = activity.test.answer.flatMap((v) => {
        const lowercased = v.toLowerCase();
        const normalized = lowercased.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        return [lowercased, normalized];
      });

      isCorrect = possibleAnswers.includes(currentValue);
    }

    setAnsweredCorrectly(isCorrect);
    userLogActivity(spotId.toString(), { answer: value, correct: isCorrect });
    if (isCorrect) {
      appStore.activitySolved(spotId, difficulty);
    }
  };

  if (!activity.test) {
    return null;
  }

  return (
    <Paper elevation={1} style={{ padding: '10px', marginTop: '20px', marginBottom: '20px' }}>
      <Typography style={{ fontWeight: 'bold' }} component="p">
        {activity.test.question}
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {activity.id_answer_type === ANSWER_TYPE.ABCD ? (
          <FormControl component="fieldset" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <RadioGroup
              aria-label="Gender"
              name="gender1"
              value={value}
              onChange={e => setValue(e.target.value)}
            >
              {ABCD.map((v, i) => {
                const { text, url } = activity.test?.options[i] || {};
                return (
                  <FormControlLabel
                    key={v}
                    value={v}
                    control={<Radio />}
                    disabled={readOnly}
                    style={{ margin: 0 }}
                    label={
                      <div>
                        {!url ? text : (
                          <CachedImage cacheName={`trip-${id_trip}`} image={url.replace(/&amp;/g, "&")}>
                            {image => <img alt="" style={{ width: '100%' }} src={image} />}
                          </CachedImage>
                        )}
                      </div>
                    }
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        ) : (
          <TextField
            label={readOnly ? "" : "Odpověď"}
            value={value}
            onChange={e => setValue(e.target.value)}
            margin="normal"
            disabled={readOnly}
          />
        )}

        {answeredCorrectly === null ? (
          null
        ) : answeredCorrectly ? (
          <Chip
            style={{ marginTop: '20px', marginBottom: '20px' }}
            label="Vaše odpověď byla správná!"
            variant="outlined"
            color="primary"
            component={Link}
            to={`/spot/${spotId}`}
          />
        ) : (
          <Chip
            style={{ marginTop: '20px', marginBottom: '20px' }}
            label="Tato odpověď není správná"
            variant="outlined"
            color="secondary"
          />
        )}

        {!readOnly && (
          <Button onClick={checkAnswer} variant="contained" color="primary">
            Odeslat!
          </Button>
        )}
      </div>
    </Paper>
  );
});
