import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useParams } from "react-router-dom";
import Spots from "./Spots";
import './admin-dashboard.css';
import TripForm from './TripForm';

export default function TripEdit() {

  const { id } = useParams();
  const tripId = parseInt(id || '', 10);
  const newTrip = tripId ? false : true;

  return (
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            {/* Trip Information */}
            <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TripForm tripId={tripId} newTrip={newTrip} />
                </Paper>
              </Grid>

            {/* Spots*/}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Spots tripId={tripId} newTrip={newTrip}/>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
  );
}
